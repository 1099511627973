import axios from 'axios';
import { DASHBOARD_API } from './service';

export const getCloister = (query: any) => {
    const url = `${DASHBOARD_API}/users`;
    const response = axios.get(url, { params: query });
    return response;
};

export const getMentor = (mentorID: string) => {
    const url = `${DASHBOARD_API}/users/${mentorID}`;
    const response = axios.get(url);
    return response;
};
