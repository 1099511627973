// @ts-ignore
import ImageGallery from 'react-image-gallery';
import {
    Col, Pagination, Row, Space, Spin, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { ExperiencesContext } from './context/experiencesContext';
import Location from './Location';
import Members from './Members';
import Links from './Links';
import { IExperience } from './context/experiences.interface';

const { Title, Paragraph } = Typography;

const Experiences = () => {
    const { t } = useTranslation('experiences');

    const {
        experiences,
        loading,
        total,
        paginator,
        onPageChange,
        fetchExperiences,
    } = useContext(ExperiencesContext);

    const location = useLocation();

    useEffect(() => {
        const tag = location.pathname.split('/')[2];
        if (tag) fetchExperiences(tag.toUpperCase());
    }, [paginator]);

    return (
        <div className="experiences" id="experiences">
            {
                loading ? (
                    <div className="experiences-loading">
                        <Spin />
                        <Title level={4}>
                            {t('LOADING')}
                        </Title>
                    </div>
                ) : total === 0 ? (
                    <div className="experiences-empty">
                        <Title level={4}>
                            {t('EMPTY')}
                        </Title>
                    </div>
                ) : (
                    experiences.map((experience: IExperience) => (
                        <Space direction="vertical" size={32} key={experience._id}>
                            <Row gutter={[32, 40]}>
                                <Col xs={24} xl={10} xxl={8}>
                                    <div className="experiences-pictures">
                                        {
                                            experience.pictures.length === 0 ? (
                                                <div className="experiences-pictures-empty">
                                                    <Title level={4}>
                                                        {t('NO_PICTURES')}
                                                    </Title>
                                                </div>
                                            ) : (
                                                <ImageGallery
                                                    items={experience.pictures}
                                                    loading={loading}
                                                    showPlayButton={false}
                                                    showNav={false}
                                                />
                                            )
                                        }
                                    </div>
                                </Col>
                                <Col xs={24} xl={14} xxl={16}>
                                    <div className="experiences-content">
                                        <div className="full__width">
                                            <Title level={3}>
                                                {experience.name}
                                            </Title>
                                            <Paragraph className="experiences-description">
                                                {experience.summary}
                                            </Paragraph>
                                        </div>

                                        <Location
                                            country={experience.country}
                                            city={experience.city}
                                            place={experience.place}
                                            eventDate={experience.visitDate}
                                        />

                                        <div className="full__width">
                                            <Title level={4}>
                                                {`${t('EXTERNAL_LINKS')} (${experience.externalLinks.length || 0})`}
                                            </Title>
                                            {
                                                experience.externalLinks.length === 0 ? (
                                                    <Paragraph className="experiences-description">
                                                        {t('NO_EXTERNAL_LINKS')}
                                                    </Paragraph>
                                                ) : (
                                                    <Links links={experience.externalLinks} />
                                                )
                                            }
                                        </div>

                                        <div className="full__width">
                                            <Title level={4}>
                                                {`${t('MEMBERS')} (${experience.members.length || 0})`}
                                            </Title>
                                            <div className="experiences-members">
                                                <Members members={experience.members} />
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                            </Row>

                            <Row className="experiences-pagination">
                                <Pagination
                                    total={total}
                                    pageSize={paginator.limit}
                                    current={paginator.page}
                                    onChange={onPageChange}
                                    showSizeChanger={false}
                                    size="small"
                                />
                            </Row>
                        </Space>
                    ))
                )
            }
        </div>
    );
};

export default Experiences;
