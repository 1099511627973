import cs from 'classnames';
import {
    Image, Col, Row, Typography, Avatar, Space, Card,
} from 'antd';
import { SiLighthouse } from 'react-icons/si';
import { useState } from 'react';
import { IoBoat } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import IMAGES from '../../../constants/images';

const { Title, Text } = Typography;

const LifeLong = () => {
    const { t } = useTranslation('home', { keyPrefix: 'LIFELONG' });

    const ITEMS = [
        {
            key: '05_YEARS',
            section: t('05_YEARS'),
            title: t('BOARD'),
            desc: t('BOARD_DESC'),
            align: 'left',
            color: 'blue',
            image: IMAGES.illustrations.lighthouse_blue,
            active: false,
        },
        {
            key: '10_YEARS',
            section: t('10_YEARS'),
            title: t('BOARD_RECTORAL'),
            desc: t('BOARD_RECTORAL_DESC'),
            align: 'right',
            color: 'yellow',
            image: IMAGES.illustrations.lighthouse_yellow,
            active: false,
        },
        {
            key: '20_YEARS',
            section: t('20_YEARS'),
            title: t('RECTORAL_CYCLE'),
            desc: t('RECTORAL_CYCLE_DESC'),
            align: 'left',
            color: 'orange',
            image: IMAGES.illustrations.lighthouse_orange,
            active: false,
        },
        {
            key: '30_YEARS',
            section: t('30_YEARS'),
            title: t('NEW_BOARDERS'),
            desc: t('NEW_BOARDERS_DESC'),
            align: 'right',
            color: 'green',
            image: IMAGES.illustrations.lighthouse_green,
            active: false,
        },
    ];

    const [items, setItems] = useState<any[]>(ITEMS);

    const handleActive = (key: string) => {
        const newItems = items.map((item) => {
            if (item.key === key) {
                return {
                    ...item,
                    active: !item.active,
                };
            }
            return item;
        });
        setItems(newItems);
    };

    return (
        <div className="lifelong flex-row center-row-center">
            <div className="timeline flex-column center-column-center">

                <div className="box flex-column center-column-center">
                    <Avatar
                        src={(
                            <IoBoat
                                color="#1B4484"
                                size={80}
                            />
                        )}
                        size={80}
                        style={{ marginBottom: 4 }}
                    />
                    <div
                        className="box__lighthouse--title"
                        style={{ textAlign: 'center', borderRadius: 4, width: 'fit-content' }}
                    >
                        <Title level={4}>
                            {t('OUTSIDERS')}
                        </Title>
                    </div>
                </div>

                {
                    items.map((item) => (
                        <Space direction="vertical" size={8}>
                            <div className="box">
                                <Row className="full__width">
                                    <Col span={10} className="flex-row end-row-end">
                                        {
                                            item.align === 'left'
                                                ? (
                                                    <div className="box__lighthouse flex-row end-row-center">
                                                        <div
                                                            className={cs('box__lighthouse--title', { [item.color]: item.color })}
                                                        >
                                                            <Title level={4}>
                                                                {item.title}
                                                            </Title>
                                                        </div>
                                                        <Image
                                                            preview={false}
                                                            src={item.image}
                                                            width={160}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => handleActive(item.key)}
                                                        />
                                                    </div>
                                                )
                                                : (
                                                    <div className="box__info">
                                                        {item.desc}
                                                    </div>
                                                )
                                        }
                                    </Col>
                                    <Col span={4} className="flex-column center-column-center">
                                        <Title level={4} style={{ textAlign: 'center' }}>
                                            {item.section}
                                        </Title>
                                        <div className="box__circle" />
                                        <div className="box__line" />
                                    </Col>
                                    <Col span={10} className="flex-row end-row-end">
                                        {
                                            item.align === 'right'
                                                ? (
                                                    <div className="box__lighthouse flex-row end-row-center">
                                                        <Image
                                                            preview={false}
                                                            src={item.image}
                                                            width={160}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => handleActive(item.key)}
                                                        />
                                                        <div className={cs('box__lighthouse--title', { [item.color]: item.color })}>
                                                            <Title level={4}>
                                                                {item.title}
                                                            </Title>
                                                        </div>
                                                    </div>
                                                )
                                                : (
                                                    <div className="box__info">
                                                        {item.desc}
                                                    </div>
                                                )
                                        }
                                    </Col>
                                </Row>
                            </div>

                            {
                                item.active && (
                                    <div className="timeline__container">
                                        <Row justify="start" align="top" className="full__width" gutter={[8, 8]}>
                                            {
                                                [1, 2, 3, 4, 5].map((item) => (
                                                    <Col xs={12} xl={8}>
                                                        <Card
                                                            bordered
                                                            hoverable
                                                            className="timeline__card"
                                                        >
                                                            <Space size={4} style={{ width: '100%', marginBottom: 8 }}>
                                                                <SiLighthouse color="#fff" />
                                                                <Text style={{ fontSize: 12 }}>
                                                                    {`Programa ${item}`}
                                                                </Text>
                                                            </Space>
                                                            <Title level={5}>
                                                                II Edición - Titulo del programa ofertante
                                                            </Title>
                                                            <Text style={{ fontSize: 14 }}>
                                                                Nombre de la Institución
                                                            </Text>
                                                        </Card>
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    </div>
                                )
                            }
                        </Space>
                    ))
                }
            </div>
        </div>
    );
};

export default LifeLong;
