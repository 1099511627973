import { useEffect, useState } from 'react';
import {
    Button, Col, Form, Input, InputNumber, List, Row, Space, Switch, Typography, notification,
} from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import PhoneNumber from '../../widgets/PhoneNumber';
import { LEADERSHIP_QUOTE } from '../../constants/quotes';
import { createLeadershipQuote } from '../../api/quotes';

const { Title, Text } = Typography;
const { TextArea } = Input;

const QuoteLeadershipForm = () => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(false);

    const RULES: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
    ];

    const RULES_EMAIL: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
        { type: 'email', message: tf('RULES.INVALID_EMAIL') },
        { type: 'string', min: 3, message: tf('RULES.MIN_LENGTH', { length: 3 }) },
    ];

    const onSubmit = () => {
        setLoading(true);
        const values = form.getFieldsValue();
        values.program[11].description = values.description;
        delete values.description;

        const payload = {
            ...values,
            fullMobile: `${values.countryCode}${values.mobile}`,
        };

        delete payload.mobile;
        delete payload.countryCode;

        createLeadershipQuote(payload)
            .then(() => {
                form.resetFields();
                notification.success({
                    message: tf('RESPONSES.CREATED_QUOTE'),
                });
            })
            .catch((error: any) => {
                const statusCode = error?.response?.data?.status;

                if (statusCode === 409) {
                    notification.warning({
                        message: tf('RESPONSES.ALREADY_QUOTE_EXISTS'),
                    });
                    return;
                }

                notification.error({
                    message: 'Error',
                    description: tf('RESPONSES.ERROR_CREATING_QUOTE'),
                });
            })
            .finally(() => setLoading(false));
    };

    const changeValueProgram = (checked: boolean, index: number) => {
        const programs = form.getFieldValue('program');
        programs[index].value = checked;
        form.setFieldsValue({
            program: programs,
        });
    };

    useEffect(() => {
        form.setFieldsValue({
            program: LEADERSHIP_QUOTE,
        });
    }, []);

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
        >
            <Form.Item name="program" hidden>
                <Input />
            </Form.Item>
            <Space direction="vertical" size={32} className="full__size">
                <Title level={3}>
                    {tf('HEADER.QUOTE_LEADERSHIP')}
                </Title>
                <Space direction="vertical" className="full__size">
                    <Row justify="space-between" align="top" gutter={[24, 32]}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={tf('INPUTS.COMPLETE_NAMES')}
                                name="names"
                                rules={RULES}
                            >
                                <Input placeholder={tf('INPUTS.COMPLETE_NAMES_PLACEHOLDER')} />
                            </Form.Item>

                            <Form.Item
                                label={tf('INPUTS.EMAIL')}
                                name="email"
                                rules={RULES_EMAIL}
                            >
                                <Input placeholder={tf('INPUTS.EMAIL_PLACEHOLDER')} />
                            </Form.Item>

                            <PhoneNumber />

                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={tf('INPUTS.ACADEMIC.INSTITUTE')}
                                name="institute"
                                rules={RULES}
                            >
                                <Input placeholder={tf('INPUTS.ACADEMIC.INSTITUTE_PLACEHOLDER')} />
                            </Form.Item>

                            <Form.Item
                                label={tf('INPUTS.PUBLIC_PROFILE')}
                                name="profilePublic"
                                rules={RULES}
                            >
                                <Input placeholder={tf('INPUTS.PUBLIC_PROFILE')} />
                            </Form.Item>

                            <Form.Item
                                label={tf('INPUTS.AUDIENCE')}
                                name="audience"
                                rules={RULES}
                            >
                                <Input placeholder={tf('INPUTS.AUDIENCE')} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={tf('INPUTS.CONTEXTUALIZE')}
                                name="contextualize"
                                rules={RULES}
                            >
                                <TextArea placeholder={tf('INPUTS.CONTEXTUALIZE')} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Title level={4}>
                                {tf('INPUTS.CHOOSE_TOOLS')}
                            </Title>
                            <div className="programs">
                                <List
                                    grid={{
                                        gutter: 16,
                                        xs: 1,
                                        sm: 2,
                                        md: 3,
                                        lg: 3,
                                        xl: 3,
                                        xxl: 3,
                                    }}
                                    bordered={false}
                                    dataSource={LEADERSHIP_QUOTE}
                                    renderItem={(item, index) => (
                                        <List.Item
                                            key={item.key}
                                            className="programs__program"
                                        >
                                            <Row justify="space-between" align="middle">
                                                <Title level={5} style={{ marginBottom: 0 }}>
                                                    <Trans
                                                        t={tf}
                                                        i18nKey="INPUTS.PROGRAM_INDEX"
                                                        values={{ index: index + 1 }}
                                                    />
                                                </Title>
                                                <Switch
                                                    size="small"
                                                    defaultChecked={false}
                                                    onChange={(checked) => changeValueProgram(checked, index)}
                                                />
                                            </Row>

                                            {
                                                item.key === 'PROGRAM_12' ? (
                                                    <Form.Item name="description" style={{ width: '100%' }}>
                                                        <TextArea
                                                            rows={2}
                                                            placeholder={tf('INPUTS.QUOTE_LEADERSHIP.SUGGESTION')}
                                                        />
                                                    </Form.Item>
                                                ) : (
                                                    <Space direction="vertical" size={4}>
                                                        <Title level={5} style={{ marginBottom: 0 }}>
                                                            {tf('INPUTS.QUOTE_LEADERSHIP.MODULE')}
                                                        </Title>
                                                        <Text>
                                                            {tf(`INPUTS.QUOTE_LEADERSHIP.${item.key}`)}
                                                        </Text>
                                                    </Space>
                                                )
                                            }

                                            <Row justify="space-between" align="middle">
                                                <Title level={5} style={{ marginBottom: 0 }}>
                                                    {tf('INPUTS.QUOTE_LEADERSHIP.HOURS')}
                                                </Title>
                                                <InputNumber
                                                    onChange={(value) => {
                                                        const programs = form.getFieldValue('program');
                                                        programs[index].hours = value;
                                                        form.setFieldsValue({
                                                            program: programs,
                                                        });
                                                    }}
                                                    placeholder="2"
                                                    min={2}
                                                    max={50}
                                                    step={2}
                                                    size="small"
                                                />
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </Col>
                    </Row>
                </Space>
                <Button
                    block
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                >
                    {tf('ACTIONS.SEND_QUOTE')}
                </Button>
            </Space>
        </Form>
    );
};

export default QuoteLeadershipForm;
