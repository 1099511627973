import { Typography, Image } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import IMAGES from '../../../constants/images';

interface Props {
    description: any;
    image?: string;
}

const { Title, Text } = Typography;

const Profile = ({
    description, image,
}: Props) => {
    const { t } = useTranslation('home', { keyPrefix: 'FOUNDATIONS' });

    return (
        <div className="foundations__section--profile">

            <Title level={1} style={{ color: '#FFF' }} className="none__margin">
                {t('PROFILE_TEACHERS')}
            </Title>

            <Text className="foundations__section--collective-text">
                {description}
            </Text>

            {
                image && (
                    <Image
                        loading="lazy"
                        preview={false}
                        width={250}
                        src={image}
                    />
                )
            }
        </div>
    );
};

export default Profile;
