import { Avatar, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ITeacher } from '../../../interfaces';
import { getCountry } from '../../../utils/countries';
import { getMembership } from '../../../utils/memberships';

interface Props {
    teacher: ITeacher;
    desc?: string ;
}

const { Title, Text } = Typography;

const TeacherCard = ({ teacher, desc }: Props) => {
    const { i18n } = useTranslation();
    const { gender } = teacher;

    const language = i18n.resolvedLanguage;

    const designation = gender !== 'HIDDEN' ? (
        teacher.gender === 'MALE' ? 'Dr.' : 'Dra.'
    ) : '';

    return (
        <a href="#" className="cloister__cards--card" style={{ cursor: 'auto' }}>
            <img src={teacher.image?.original} alt="" />
            <div className="cloister__cards--card-text">
                <Title level={5}>
                    {`${designation || ''} ${teacher?.name} ${teacher?.lastName}`}
                </Title>
                <Text className="cloister__cards--card-text-job">
                    {
                        desc || teacher?.luces?.signature[language as 'es' | 'en' | 'pt']
                    }
                </Text>
                <Space size={8}>
                    <Avatar
                        size={24}
                        shape="square"
                        style={{ width: 24, height: 16 }}
                        src={getCountry(teacher.country as string)?.flag}
                    />
                    <Avatar
                        size={20}
                        shape="square"
                        src={getMembership(teacher.membership.keyName as string)}
                    />
                </Space>
            </div>
        </a>
    );
};

export default TeacherCard;
