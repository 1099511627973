import {
    Card, List, Space, Typography,
} from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { BiBullseye } from 'react-icons/bi';
import ItemData from '../../Item';
import { IOffer, IProfileTeacher } from '../../../interfaces';

interface Props {
    modality: string;
    offerType: string;
    languages: string[];
    workload: number;
    profileTeacher: Array<IProfileTeacher>;
    offer: Array<IOffer>;
    profileTeacherDescription: string;
}

const { Title, Text } = Typography;

const Information = ({
    modality, offerType, languages, workload, profileTeacher, offer, profileTeacherDescription,
}: Props) => {
    const { t } = useTranslation('courses', { keyPrefix: 'DETAIL' });
    const { t: th } = useTranslation('home', { keyPrefix: 'EAGLES' });

    return (
        <div>
            <Card>
                <ItemData
                    label={t('MODALITY')}
                    value={t(modality)}
                />

                <ItemData
                    label={t('OFFER')}
                    value={t(offerType)}
                />

                <ItemData
                    label={t('WORKLOAD')}
                    value={`${workload} ${t('HOURS')}`}
                />
            </Card>

            <Title level={3} className="full__width">
                {t('LANGUAGES')}
            </Title>

            <Text className="full__width">
                {languages?.map((language) => t(language)).join(', ')}
            </Text>

            <Title level={3} className="full__width">
                {t('PROFILE_TEACHER')}
            </Title>

            <Space direction="vertical" size={4}>
                <Text>
                    <Trans
                        t={th}
                        i18nKey={profileTeacherDescription}
                        components={{
                            b: <b />,
                        }}
                    />
                </Text>
                {
                    profileTeacher.length && (
                        <List
                            split={false}
                            dataSource={profileTeacher}
                            renderItem={(item, index) => (
                                <List.Item
                                    key={index}
                                    className="none__padding--bottom"
                                >
                                    <Space size={8} align="center">
                                        <BiBullseye size={12} color="#1E3A90" style={{ verticalAlign: 'middle' }} />
                                        <Text>
                                            {`${item.total} ${item.type}`}
                                        </Text>
                                    </Space>
                                </List.Item>
                            )}
                        />
                    )
                }
            </Space>

            {
                offer.length && (
                    <Space direction="vertical" size={4} className="full__width">
                        <Title level={3} className="none__margin--bottom">
                            {t('GROUPS')}
                        </Title>
                        <List
                            split={false}
                            dataSource={offer}
                            renderItem={(item, index) => (
                                <List.Item
                                    key={index}
                                    className="none__padding--bottom"
                                >
                                    <Space size={8} align="center">
                                        <BiBullseye size={12} color="#1E3A90" style={{ verticalAlign: 'middle' }} />
                                        <Text>
                                            {`${item.total} ${item.value}`}
                                        </Text>
                                    </Space>
                                </List.Item>
                            )}
                        />
                    </Space>
                )
            }
        </div>
    );
};

export default Information;
