import { Col, Row } from 'antd';
import { IMember } from './context/experiences.interface';
import MemberCard from './widgets/MemberCard';

interface Props {
    members: IMember[];
}

const Members = ({ members }: Props) => (
    <Row gutter={[24, 20]} className="full__width">
        {
            members.map((member: IMember, index: number) => (
                <Col xs={24} md={12} xl={24} xxl={12} key={index}>
                    <MemberCard member={member} />
                </Col>
            ))
        }
    </Row>
);

export default Members;
