import {
    Col, Row, Typography, Image,
} from 'antd';

import { Trans, useTranslation } from 'react-i18next';
import IMAGES from '../../constants/images';

const { Title } = Typography;

const Portrait = () => {
    const { t } = useTranslation('home', { keyPrefix: 'PORTRAIT' });

    return (
        <div className="portrait" id="commitment">
            <Row className="full__width full__height" gutter={[24, 40]}>
                {/* <div className="flex-column center-column-start full__height portrait__text-container"> */}
                <Col
                    xs={24}
                    xl={12}
                    className="flex-column center-column-start full__height"
                >
                    <Title level={3} className="portrait__text-description">
                        <Trans
                            t={t}
                            i18nKey="DESCRIPTION_1"
                            components={{ b: <b /> }}
                            className="portrait__text-description"
                        />
                    </Title>
                    <Title level={3} className="portrait__text-description">
                        <Trans
                            t={t}
                            i18nKey="DESCRIPTION_2"
                            components={{ b: <b /> }}
                            className="portrait__text-description"
                        />
                    </Title>
                    <Title level={3} className="portrait__text-description">
                        <Trans
                            t={t}
                            i18nKey="DESCRIPTION_3"
                            components={{ b: <b /> }}
                            className="portrait__text-description"
                        />
                    </Title>
                    <Title level={3} className="portrait__text-description">
                        <Trans
                            t={t}
                            i18nKey="DESCRIPTION_4"
                            components={{ b: <b /> }}
                            className="portrait__text-description"
                        />
                    </Title>
                </Col>
                <Col
                    span={0}
                    xl={12}
                    className="flex-column center-column-center full__height responsive"
                >
                    <Image
                        className="portrait__image"
                        preview={false}
                        width={400}
                        height={400}
                        loading="lazy"
                        src={IMAGES.portrait.presentation}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default Portrait;
