import { useEffect, useState } from 'react';
import { Drawer, Menu, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { useLocation, useNavigate } from 'react-router-dom';
import { SelectLanguage } from '../SelectLanguage';

interface INavbarMobileProps {
  openMenu: boolean;
  itemsMenu: any[];
  closeMenu: () => void;
}

const NavbarMobile = ({
    openMenu, itemsMenu, closeMenu,
}: INavbarMobileProps) => {
    const [optionActive, setOptionActive] = useState<string | undefined>('');

    const MAIN_PAGE: string = '/';

    const { pathname } = useLocation();
    const navigate = useNavigate();

    const onClick: MenuProps['onClick'] = (e) => {
        const { key } = e;
        setOptionActive(key.toString());

        const item = itemsMenu.find((item) => item.key === key);
        if (item && item.children && item.children.length > 0) {
            const firstChildKey = item.children[0].key;
            navigate(`${key.toString()}#${firstChildKey}`);
        } else {
            navigate(key.toString());
        }
        closeMenu();
    };

    useEffect(() => {
        const option = itemsMenu.find((item) => item.key === pathname);
        if (option) {
            setOptionActive(option.key);
        } else {
            setOptionActive(MAIN_PAGE);
        }
    }, [pathname]);

    return (
        <Drawer
            open={openMenu}
            onClose={closeMenu}
            extra={
                <SelectLanguage />
            }
        >
            <Menu
                onClick={onClick}
                items={itemsMenu}
                mode="inline"
                style={{ width: '100%', float: 'right', justifyContent: 'flex-end' }}
            />
        </Drawer>
    );
};

export default NavbarMobile;
