/* eslint-disable react/no-danger */
import {
    Card, Col, Divider, Row, Space, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Teacher from './Teacher';
import { ITeacher } from '../../../interfaces';

interface Props {
    presentation: string;
    directiveProgram: ITeacher;
    modality: string[];
    offerType: string[];
    languages: string[];
    workload: number;
    objective: string;
    target: string;
    profileTeacher: string;
    registerType: 'COLLECTIVE' | 'INDIVIDUAL';
    registerDetail: string;
    quotes: number;
    cost: string;
    costDetail: string;
    currency: string;
    discount: string;
}

const { Title, Text } = Typography;

const Summary = ({
    presentation, directiveProgram, modality, offerType, languages, workload, objective, target,
    profileTeacher, registerType, quotes, cost, currency, discount, registerDetail, costDetail,
}: Props) => {
    const { t } = useTranslation('courses', { keyPrefix: 'DETAIL' });

    return (
        <Space direction="vertical" size={24}>
            <Card>
                <Row justify="start" align="middle" gutter={[32, 24]}>
                    <Col className="item__summary">
                        <Text className="item__summary--text-label">
                            {t('MODALITY')}
                        </Text>
                        <Text className="item__summary--text-value">
                            {
                                modality?.map((mod) => t(mod)).join(', ')
                            }
                        </Text>
                    </Col>
                    <Col>
                        <Divider
                            type="vertical"
                            style={{ height: 40 }}
                        />
                    </Col>
                    <Col className="item__summary">
                        <Text className="item__summary--text-label">
                            {t('OFFER')}
                        </Text>
                        <Text className="item__summary--text-value">
                            {
                                offerType?.map((offer) => t(offer)).join(', ')
                            }
                        </Text>
                    </Col>
                    <Col>
                        <Divider
                            type="vertical"
                            style={{ height: 40 }}
                        />
                    </Col>
                    <Col className="item__summary">
                        <Text className="item__summary--text-label">
                            {t('WORKLOAD')}
                        </Text>
                        <Text className="item__summary--text-value">
                            {`${workload} ${t('HOURS')}`}
                        </Text>
                    </Col>
                    <Col>
                        <Divider
                            type="vertical"
                            style={{ height: 40 }}
                        />
                    </Col>
                    <Col className="item__summary">
                        <Text className="item__summary--text-label">
                            {t('LANGUAGES')}
                        </Text>
                        <Text className="item__summary--text-value">
                            {languages?.map((language) => t(language)).join(', ')}
                        </Text>
                    </Col>
                    <Col>
                        <Divider
                            type="vertical"
                            style={{ height: 40 }}
                        />
                    </Col>
                    <Col className="item__summary">
                        <Text className="item__summary--text-label">
                            {t('REGISTRATION')}
                        </Text>
                        <Space direction="vertical" size={4}>
                            <Text className="item__summary--text-value">
                                {t(registerType)}
                            </Text>
                            <Text className="item__summary--text-value info">
                                <div dangerouslySetInnerHTML={{ __html: registerDetail }} />
                            </Text>
                        </Space>
                    </Col>
                    <Col>
                        <Divider
                            type="vertical"
                            style={{ height: 40 }}
                        />
                    </Col>
                    <Col className="item__summary">
                        <Text className="item__summary--text-label">
                            {t('QUOTES')}
                        </Text>
                        <Text className="item__summary--text-value">
                            {`${quotes} ${t('PARTICIPANTS')}`}
                        </Text>
                    </Col>
                    <Col>
                        <Divider
                            type="vertical"
                            style={{ height: 40 }}
                        />
                    </Col>
                </Row>
            </Card>
            <Text>
                <div dangerouslySetInnerHTML={{ __html: presentation }} />
            </Text>

            <Title level={3} className="none__margin--bottom">
                {t('TARGET')}
            </Title>

            <Text className="item__summary--text-value">
                <div dangerouslySetInnerHTML={{ __html: objective }} />
            </Text>

            <Title level={3} className="none__margin--bottom">
                {t('TARGET_AUDIENCE')}
            </Title>

            <Text className="item__summary--text-value">
                <div dangerouslySetInnerHTML={{ __html: target }} />
            </Text>

            <Title level={3} className="none__margin--bottom">
                {t('PROFILE_TEACHER')}
            </Title>

            <Text className="item__summary--text-value">
                <div dangerouslySetInnerHTML={{ __html: profileTeacher }} />
            </Text>

            <Title level={3} className="none__margin--bottom">
                {t('COST_DETAIL')}
            </Title>

            <div>
                <Text className="item__summary--text-value">
                    <div dangerouslySetInnerHTML={{ __html: costDetail }} />
                </Text>

                <Text strong className="item__summary--text-value none__margin">
                    <div dangerouslySetInnerHTML={{ __html: discount }} />
                </Text>

            </div>

            <Title level={3} className="none__margin--bottom">
                {t('DIRECTIVE_PROGRAM')}
            </Title>

            <Teacher teacher={directiveProgram} index={0} />
        </Space>
    );
};

export default Summary;
