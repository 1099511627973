import {
    Avatar,
    Col, List, Row, Space, Typography,
} from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import PreEnrollment from './PreEnrollment';
import { IEdition } from '../../../interfaces';

interface Props {
    name: string;
    welcomePhrase: string;
    logo: string;
    background: string;
    editions: IEdition[];
    color?: string;
}

const { Title, Text } = Typography;

const Header = ({
    name, editions, logo, welcomePhrase, background, color = '#FFF',
}: Props) => {
    const { t } = useTranslation('courses', { keyPrefix: 'DETAIL' });

    return (
        <div
            className="course__header"
            style={{
                backgroundImage: `url(${background})`,
            }}
        >
            <div className="course__header--effect" />

            <div className="course__header--logo" style={{ backgroundColor: color }}>
                <Avatar
                    size={80}
                    src={logo}
                    shape="square"
                />
            </div>
            <div className="course__header--container flex-row center-row-center">
                <Row
                    align="middle"
                    gutter={[24, 24]}
                    justify="space-between"
                    className="full__width"
                >
                    <Col xs={24} xl={14}>
                        <List.Item.Meta
                            className="full__width"
                            title={(
                                <Space direction="vertical" size={8}>
                                    <Text>
                                        <div dangerouslySetInnerHTML={{ __html: welcomePhrase }} />
                                    </Text>
                                    <Title level={3}>
                                        {name}
                                    </Title>
                                </Space>
                            )}
                            description={(
                                editions.map((edition: IEdition) => (
                                    <Text className="tag" style={{ marginBottom: 8 }}>
                                        {`${edition.index}º ${t('EDITION')} - ${t('OFFER')} ${edition.months}/${edition.year}`}
                                    </Text>
                                ))
                            )}
                        />
                    </Col>
                    <Col xs={24} xl={8}>
                        {/* <PreEnrollment /> */}
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Header;
