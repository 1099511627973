import {
    Row, Col, Image, Typography,
} from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import IMAGES from '../../../constants/images';

const { Title, Text } = Typography;

const RectoralBoard = () => {
    const { t } = useTranslation('institutional', { keyPrefix: 'RECTORALBOARD' });

    const items: String[] = [
        'TITLE_RELATIONSHIP_RECTORAL_BOARD',
        'PARAGRAPH_1',
    ];

    return (
        <div className="section__training" id="relatioship-rectoral-board">
            <Title level={2}>
                {t('TITLE_RELATIONSHIP_RECTORAL_BOARD')}
            </Title>
            <div className="section__training--information-line" />
            <Row className="full__width full__height" gutter={[24, 40]}>
                <Col
                    span={0}
                    xl={12}
                    className="flex-column center-column-center full__height responsive"
                >
                    <Image
                        className="portrait__image"
                        preview={false}
                        loading="lazy"
                        src={IMAGES.logo.rectoral_logo}
                    />
                </Col>
                <Col
                    xs={24}
                    xl={12}
                    className="flex-column center-column-start full__height"
                >
                    <Title level={3} className="portrait__text-description">
                        <Trans
                            t={t}
                            i18nKey="PARAGRAPH_1"
                            components={{ b: <b /> }}
                            className="portrait__text-description"
                        />
                    </Title>
                </Col>
            </Row>
        </div>
    );
};

export default RectoralBoard;
