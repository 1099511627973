import { Space, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ICourse } from '../../../interfaces';

import Summary from './Summary';
import TeacherList from './TeacherList';
import StudyPlan from './StudyPlan';
import Experiences from '../../Experiences';

const { Text } = Typography;

const ManageTabs = ({ course }: { course: ICourse }) => {
    const { t } = useTranslation('courses', { keyPrefix: 'DETAIL' });

    const items = [
        {
            label: t('SUMMARY'),
            key: 'SUMMARY',
            children: (
                <Summary
                    presentation={course.presentation}
                    directiveProgram={course.directiveProgram}
                    modality={course.modality}
                    offerType={course.offerType}
                    languages={course.languages}
                    workload={course.workload}
                    objective={course.objective}
                    target={course.target}
                    profileTeacher={course.profileTeacher}
                    registerType={course.registerType}
                    quotes={course.quotes}
                    cost={course.cost}
                    currency={course.currency}
                    discount={course.discount}
                    registerDetail={course.registerDetail}
                    costDetail={course.costDetail}
                />
            ),
        },
        {
            label: t('MENTORS'),
            key: 'MENTORS',
            children: (
                <TeacherList teachers={course.teachers} />
            ),
        },
        {
            label: t('SYLLABUS'),
            key: 'SYLLABUS',
            children: (
                <StudyPlan
                    studyPlan={course.studyPlan}
                    duration={course.duration}
                    methodology={course.methodology}
                />
            ),
        },
        {
            label: t('EXPERIENCES'),
            key: 'EXPERIENCES',
            children: (
                <Space direction="vertical" size={24}>
                    <Text>
                        {t('EXPERIENCES_PRESENTATION')}
                    </Text>
                    <Experiences />
                </Space>
            ),
        },
    ];

    return <Tabs items={items} />;
};

export default ManageTabs;
