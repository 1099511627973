import {
    Avatar, Col, Row, Space, Spin, Typography, notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { getCloister } from '../api/cloister';
import { ITeacher } from '../interfaces';
import TeacherCard from '../components/Course/components/TeacherCard';
import IMAGES from '../constants/images';
import ModalWrapper from '../components/Modals';
import Mentor from './Mentor';

const { Title, Text } = Typography;

const Cloister = () => {
    const { t, i18n } = useTranslation('cloister');

    const [loading, setLoading] = useState<boolean>(false);
    const [mentors, setMentors] = useState<ITeacher[]>([]);
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const [hasNextPage, setHasNextPage] = useState<boolean>(true);
    const [open, setOpen] = useState<boolean>(false);
    const [mentorSelected, setMentorSelected] = useState<string | null>(null);

    const language = i18n.resolvedLanguage;

    const fetchCloister = () => {
        setLoading(true);
        setMentors([]);

        const query: any = {
            limit: 10,
            role: 'MENTOR',
            page,
            order: 'ASC',
        };

        getCloister(query)
            .then((response: any) => {
                setMentors([...mentors, ...response.data]);
                setTotal(response.meta.itemCount);
                setHasNextPage(response.meta.hasNextPage);
            }).catch((error: any) => {
                const description = error?.response?.data?.message || error?.message;

                notification.error({
                    message: 'ERROR',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    const showMoreMentors = () => {
        setPage(page + 1);
    };

    useEffect(() => {
        fetchCloister();
    }, [page]);

    return (
        <Space direction="vertical" size={32} className="full__width">

            { open && mentorSelected && (
                <ModalWrapper
                    visible={open}
                    onCancel={() => setOpen(false)}
                >
                    <Mentor
                        mentorID={mentorSelected as string}
                    />
                </ModalWrapper>
            )}

            <div
                className="cloister__header"
                style={{
                    backgroundImage: `url(${IMAGES.illustrations.sunflowers})`,
                }}
            >
                <Title level={2} className="none__margin">
                    {t('CLOISTER')}
                </Title>
            </div>
            <div className="flex-column start-column-center full__width cloister">

                <div className="flex-column center-column-center">
                    <Title level={1} className="none__margin">
                        {t('KNOW_ALL_CLOISTER')}
                    </Title>
                    <Text>
                        {t('KNOW_ALL_CLOISTER_DESC')}
                    </Text>
                </div>

                <Row
                    align="top"
                    gutter={[8, 24]}
                    justify="space-around"
                    className="cloister__cards full__width"
                >
                    {
                        loading ? (
                            <div className="flex-column-center center-column-center">
                                <Spin />
                            </div>
                        ) : mentors.map((item, index) => (
                            <Col
                                xs={24}
                                sm={12}
                                xl={8}
                                xxl={6}
                                key={index}
                                style={{ padding: '0 6px 32px' }}
                                onClick={() => {
                                    setMentorSelected(item._id);
                                    setOpen(true);
                                }}
                            >
                                <TeacherCard teacher={item} desc={item.luces?.jobPosition[language as 'es' | 'en' | 'pt']} />
                            </Col>
                        ))
                    }
                </Row>

                {
                    hasNextPage && (
                        <Avatar
                            size={60}
                            className="cloister__button"
                            onClick={() => showMoreMentors()}
                            src={<AiOutlinePlusCircle size={32} />}
                        />
                    )
                }
            </div>
        </Space>
    );
};

export default Cloister;
