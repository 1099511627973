import {
    Avatar, List, Space, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { ITeacher } from '../../../interfaces';
import { Contact } from '../../../widgets/Contact';
import { getCountry } from '../../../utils/countries';

interface TeacherProps {
    teacher: ITeacher;
    index: number;
}

const { Title, Text } = Typography;

const Teacher = ({ teacher, index }: TeacherProps) => {
    const { i18n } = useTranslation();
    const { academic } = teacher;

    const designation = teacher.gender !== 'HIDDEN' ? (
        teacher.gender === 'MALE' ? 'Dr.' : 'Dra.'
    ) : '';

    const language = i18n.resolvedLanguage;

    return (
        <Space direction="vertical" size={8} className="full__width">
            <List.Item key={index}>
                <List.Item.Meta
                    avatar={(
                        teacher?.image?.original
                            ? (
                                <Avatar
                                    size={64}
                                    src={teacher?.image?.original}
                                />
                            ) : (
                                <Avatar
                                    size={64}
                                    src={(
                                        <Title
                                            level={3}
                                            style={{ color: '#FFF' }}
                                            className="none__margin"
                                        >
                                            {teacher?.name?.charAt(0)}
                                        </Title>
                                    )}
                                    className="flex-row center-row-center"
                                    style={{ backgroundColor: '#1E3A90' }}
                                />
                            )
                    )}
                    title={(
                        <Title level={5} className="none__margin--bottom">
                            {`${designation} ${teacher.name} ${teacher.lastName}`}
                        </Title>
                    )}
                    description={(
                        <Space size={4}>
                            {`${getCountry(teacher.country)?.nativeName} - ${teacher?.luces?.jobPosition[language as 'es' | 'en' | 'pt']}`}
                        </Space>
                    )}
                />
            </List.Item>
            <Text>
                {teacher.academic.biography}
            </Text>
            <Contact data={teacher.contact} email={teacher.email} />
        </Space>
    );
};

export default Teacher;
