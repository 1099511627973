import moment from 'moment';
import {
    Avatar, Col, List, Row, Space, Typography, message,
} from 'antd';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarContext } from './context/calendarContext';
import { IEvent, IEventMonth } from './context/calendar.interface';
import { getCountry } from '../../utils/countries';

const { Title, Text } = Typography;

const Calendar = () => {
    const { t } = useTranslation('calendar');
    const { loading, calendar, fetchCalendar } = useContext(CalendarContext);

    const handleLink = (event: IEvent) => {
        if (event?.meet) {
            window.open(event?.meet, '_blank');
        } else {
            message.info(t('NO_LINK'));
        }
    };

    useEffect(() => {
        fetchCalendar();
    }, []);

    return (
        loading ? (
            <div>
                {t('GETTING_CALENDAR')}
            </div>
        ) : calendar && (
            <div className="calendar">
                <Title level={2}>
                    {t('CALENDAR_EVENTS')}
                </Title>
                <div className="section__training--information-line" />

                <div className="calendar__container">
                    <Row gutter={0} justify="space-between" align="middle" className="full__width">
                        <Col span={24} style={{ overflow: 'hidden' }}>
                            <div className="calendar__container--events">
                                {
                                    calendar.map((item: IEventMonth, index: number) => (
                                        <div className="calendar__events" key={`MONTH_${index}`}>
                                            <div className="calendar__events--month">
                                                <Title level={4} className="none__margin">
                                                    {t(`MONTHS.${item.month}`)}
                                                </Title>
                                            </div>
                                            <div className="calendar__events--list">
                                                {
                                                    item.events.length > 0 && (
                                                        item.events.map((event: IEvent) => {
                                                            const time = moment(event?.date).format('HH:mm a');
                                                            const date = moment(event?.date).format('LL');

                                                            return (
                                                                <List.Item
                                                                    key={event._id}
                                                                    onClick={() => handleLink(event)}
                                                                >
                                                                    <List.Item.Meta
                                                                        avatar={(
                                                                            <Avatar
                                                                                size={28}
                                                                                src={event.country && getCountry(event?.country)?.flag}
                                                                            />
                                                                        )}
                                                                        className="calendar__events--list-item"
                                                                        title={(
                                                                            <Space direction="vertical" size={0}>
                                                                                <Title level={5} className="none__margin">
                                                                                    {event.name}
                                                                                </Title>
                                                                                <Text className="calendar__events--list-item-region">
                                                                                    {`${event.region} - ${getCountry(event?.country)?.nativeName}`}
                                                                                </Text>
                                                                            </Space>
                                                                        )}
                                                                        description={(
                                                                            <Text className="calendar__events--list-item-date">
                                                                                {`${date} ${t('AT')} ${time}`}
                                                                            </Text>
                                                                        )}
                                                                    />
                                                                </List.Item>
                                                            );
                                                        })
                                                    )
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    );
};

export default Calendar;
