import cs from 'classnames';
import {
    Image, Col, Row, Typography, Avatar, Space, Card,
} from 'antd';
import { SiLighthouse } from 'react-icons/si';
import { useState } from 'react';
import { IoBoat } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import IMAGES from '../../../constants/images';

const { Title, Text } = Typography;

const LifeLongLuces = () => {
    const { t, i18n } = useTranslation('home', { keyPrefix: 'LIFELONG' });

    const localeLImage = () => {
        if (i18n.language === 'en') {
            return IMAGES.illustrations.life_long_learning.EN;
        }
        if (i18n.language === 'es') {
            return IMAGES.illustrations.life_long_learning.ES;
        }
        return IMAGES.illustrations.life_long_learning.PT;
    };

    return (
        <div className="flex-row center-row-center" id="lifelonglearning">
            <div className="flex-column center-column-center">
                <Image
                    preview={false}
                    src={localeLImage()}
                />
            </div>
        </div>
    );
};

export default LifeLongLuces;
