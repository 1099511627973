import { Row, Col, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

const Collective = () => {
    const { t } = useTranslation('home', { keyPrefix: 'FOUNDATIONS' });

    return (
        <div className="foundations__section--collective">
            <Title level={2}>
                {t('COLLECTIVE_EXECUTIVE_COMPETENCIES')}
            </Title>

            <Row gutter={[24, 64]} align="top" justify="space-between" className="full__width">
                <Col xs={24} lg={12}>
                    <Text className="foundations__section--collective-text">
                        <Trans
                            t={t}
                            i18nKey="COLLECTIVE_EXECUTIVE_COMPETENCIES_ITEM_1"
                            components={{
                                b: <b />,
                            }}
                        />
                    </Text>
                </Col>
                <Col xs={24} lg={12}>
                    <Text className="foundations__section--collective-text">
                        <Trans
                            t={t}
                            i18nKey="COLLECTIVE_EXECUTIVE_COMPETENCIES_ITEM_2"
                            components={{
                                b: <b />,
                            }}
                        />
                    </Text>
                </Col>
            </Row>
        </div>
    );
};

export default Collective;
