import { Image } from 'antd';
import IMAGES from '../../constants/images';
import styles from './styles.module.scss';

const WhatsAppButton = () => (
    <a
        href="https://wa.me/5548991271973?text=Contato%20de%20Luces"
        className={styles.whatsappButton}
        target="_blank"
        rel="noopener noreferrer"
    >
        <Image
            className={styles.whatsappImage}
            preview={false}
            loading="lazy"
            src={IMAGES.logo.whatsapp}
        />
    </a>
);

export default WhatsAppButton;
