import moment from 'moment';
import {
    Avatar, List, Space, Typography,
} from 'antd';
import { COUNTRIES } from '../../utils/countries';

const { Title, Text } = Typography;

interface Props {
    country: string;
    city: string;
    place: string;
    eventDate: Date;
}

const Location = ({
    country, city, place, eventDate,
}: Props) => {
    const findImage = (country: string) => COUNTRIES.filter((item: any) => item.code === country)[0].flag;
    return (
        <div className="full__width">
            <List.Item.Meta
                avatar={(
                    <Avatar
                        size={45}
                        src={findImage(country)}
                    />
                )}
                title={(
                    <Title level={4} className="card-title none__margin--bottom">
                        {city}
                    </Title>
                )}
                description={(
                    <Space direction="vertical" size={8}>
                        <Text>
                            {place}
                        </Text>
                        <Text className="item__summary--text-value info">
                            {moment(eventDate).format('lll')}
                        </Text>
                    </Space>
                )}
            />
        </div>
    );
};

export default Location;
