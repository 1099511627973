import { Menu, MenuProps, Image } from 'antd';

import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BiMenu } from 'react-icons/bi';

import NavbarMobile from './NavbarMobile';

import IMAGES from '../../constants/images';
import { SelectLanguage } from '../SelectLanguage';

interface IMenuItem {
    key: string,
    label: string,
    children?: IMenuItem[],
}

const Navbar = () => {
    const { t, i18n } = useTranslation('shared', { keyPrefix: 'NAVBAR' });
    const [optionActive, setOptionActive] = useState<string | undefined>('');
    const [openMenu, setOpenMenu] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                // Use setTimeout to ensure the element is present in the DOM
                setTimeout(() => {
                    element.scrollIntoView({ behavior: 'smooth' });
                }, 0);
            }
        }
    }, [location]);

    const MAIN_PAGE: string = '/';
    const image: string = IMAGES.logo.landscape.ES;

    const itemsMenu: IMenuItem[] = [
        {
            key: '/',
            label: t('HOME'),
            children: [
                {
                    key: '/#commitment',
                    label: t('COMMITMENT_LUCES'),
                },
                {
                    key: '/#lifelonglearning',
                    label: t('LIFELONGLEARNING'),
                },
                {
                    key: '/briefcase',
                    label: t('BRIEFCASE_LUCES'),
                },
                {
                    key: '/#macro-strategic-agenda',
                    label: t('MACRO_STRATEGIC_AGENDA'),
                },
                {
                    key: '/#education-is-the-light',
                    label: t('EDUCATION_IS_THE_LIGHT'),
                },
                {
                    key: '/#experiences',
                    label: t('EXPERIENCES'),
                },
            ],
        },
        {
            key: '/institutional',
            label: t('INSTITUTIONAL'),
            children: [
                {
                    key: '/institutional#relatioship-rectoral-board',
                    label: t('RELATIONSHIP_RECTORAL_BOARD'),
                },
                {
                    key: '/institutional#purpose',
                    label: t('PURPOSE_LUCES'),
                },
                {
                    key: '/institutional#insipiration',
                    label: t('INSPIRATION_LUCES'),
                },
                // {
                //     key: '/institutional#governance',
                //     label: t('GOVERNANCE'),
                // },
            ],
        },
        {
            key: 'briefcase',
            label: t('BRIEFCASE'),
            children: [
                {
                    key: 'briefcase#programs',
                    label: t('PROGRAMS'),
                },
                // {
                //     key: 'briefcase#executive_education',
                //     label: t('EXECUTIVE_EDUCATION'),
                // },
                // {
                //     key: 'briefcase#educational-events',
                //     label: t('EDUCATIONAL_EVENTS'),
                // },
                // {
                //     key: 'briefcase#specific-services',
                //     label: t('SPECIFIC_SERVICES'),
                // },
                // {
                //     key: 'briefcase#benchmarkies',
                //     label: t('BENCHMARKIES'),
                // },
            ],
        },
        {
            key: 'foundations',
            label: t('FOUNDATIONS'),
            children: [
                {
                    key: 'foundations#pedagogical-model',
                    label: t('LUCES_EDUCATIONAL_MODEL'),
                },
                {
                    key: 'foundations#parameters',
                    label: t('PARAMETERS'),
                },
                // {
                //     key: 'foundations#lifelong-learning',
                //     label: t('LIFELONG_LEARNING'),
                // },
                {
                    key: 'foundations#competency-management',
                    label: t('COMPETENCY_MANAGEMENT'),
                },
                {
                    key: 'foundations#change-management',
                    label: t('CHANGE_MANAGEMENT'),
                },
                {
                    key: 'foundations#transversalities-11in',
                    label: t('TRANSVERSALITIES_11IN'),
                },
                // {
                //     key: 'foundations#creative-thinking',
                //     label: t('CREATIVE_THINKING'),
                // },
            ],
        },
        {
            key: 'partners',
            label: t('PARTNERS'),
            children: [
                {
                    key: 'supporters#academic-partners',
                    label: t('ACADEMIC_PARTNERS'),
                },
                {
                    key: 'supporters#business-partners',
                    label: t('BUSINESS_PARTNERS'),
                },
            ],
        },
        {
            key: 'cloister',
            label: t('CLOISTER'),
            children: [
                {
                    key: 'cloister#experience',
                    label: t('EXPERIENCE'),
                },
                // {
                //     key: 'cloister#teachers-profile',
                //     label: t('TEACHERS_PROFILE'),
                // },
                // {
                //     key: 'cloister#list-cv',
                //     label: t('LIST_CV'),
                // },
            ],
        },
        {
            key: 'contact',
            label: t('CONTACT'),
        },
    ];

    const onClick: MenuProps['onClick'] = (e) => {
        const { key } = e;
        setOptionActive(key.toString());
        const item = itemsMenu.find((item) => item.key === key);
        if (item && item.children && item.children.length > 0) {
            const firstChildKey = item.children[0].key;
            navigate(`${key.toString()}#${firstChildKey}`);
        } else {
            navigate(key.toString());
        }
    };

    useEffect(() => {
        const option = itemsMenu.find((item) => item.key === pathname);
        if (option) {
            setOptionActive(option.key);
        } else {
            setOptionActive(MAIN_PAGE);
        }
    }, [pathname]);

    return (
        <div className="navbar">

            <Image
                loading="lazy"
                src={image}
                alt="Logo luces rectoral board"
                height="100%"
                onClick={() => navigate('/')}
                preview={false}
                className="navbar__logo"
            />

            <BiMenu
                className="navbar__icon"
                onClick={() => setOpenMenu(!openMenu)}
            />

            <NavbarMobile
                openMenu={openMenu}
                itemsMenu={itemsMenu}
                closeMenu={() => setOpenMenu(false)}
            />

            <div className="navbar__menu">
                <Menu
                    onClick={onClick}
                    items={itemsMenu}
                    mode="horizontal"
                    style={{ width: '100%', float: 'right', justifyContent: 'flex-end' }}
                />
                <SelectLanguage />
            </div>
        </div>
    );
};
export default Navbar;
