import cs from 'classnames';
import {
    Col, Row, Space, Tooltip, Typography,
} from 'antd';
import { BsFillInfoSquareFill } from 'react-icons/bs';

interface ItemProps {
    label: string;
    value: string | number | React.ReactNode;
    info?: boolean;
    infoLabel?: string;
    actionRequired?: boolean;
    actionLabel?: string;
    actionIcon?: React.ReactNode;
    action?: () => void;
}

const { Text } = Typography;

const ItemData = ({
    label, value, info = false, infoLabel = '', actionRequired = false, actionLabel = '', actionIcon = null, action = () => {},
}: ItemProps) => (
    <Row justify="space-between" align="middle" style={{ width: '100%' }}>
        <Col span={10}>
            <Space size={8}>
                <Text strong>
                    {label}
                </Text>
                {
                    info && (
                        <Tooltip placement="top" title={infoLabel}>
                            <BsFillInfoSquareFill
                                size={12}
                                color="#687385"
                                style={{ verticalAlign: 'middle' }}
                            />
                        </Tooltip>
                    )
                }
            </Space>
        </Col>
        <Col span={14}>
            <Space size={4} align="center">
                <Text
                    className={cs('item--text-value', { link: actionRequired })}
                    style={{

                    }}
                    onClick={actionRequired ? action : () => {}}
                >
                    {value}
                </Text>
                {
                    actionRequired && (
                        <Tooltip placement="right" title={actionLabel}>
                            <Text onClick={action}>
                                {actionIcon}
                            </Text>
                        </Tooltip>
                    )
                }
            </Space>
        </Col>
    </Row>
);

export default ItemData;
