import {
    Avatar, Col, notification, Row, Space, Typography, Button, Spin,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFeedback } from '../../../api/feedback';
import { IFeedback } from '../../../interfaces';
import { COUNTRIES } from '../../../utils/countries';
import FeedbackCard from '../../../widgets/FeedbackCard';
import FeedbackForm from '../../Forms/FeedbackForm';
import ModalWrapper from '../../Modals';

const { Title, Text } = Typography;

const Feedback = () => {
    const { t } = useTranslation('home', { keyPrefix: 'BRIEFCASE' });

    const [loading, setLoading] = useState<boolean>(false);
    const [feedbacks, setFeedbacks] = useState<IFeedback[]>([]);
    const [feedbackSelected, setFeedbackSelected] = useState<IFeedback | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleFeedback = (feedback: IFeedback) => setFeedbackSelected(feedback);

    const findImage = (country: string) => COUNTRIES.filter((item: any) => item.code === country)[0].flag;

    const fetchFeedbacks = () => {
        setLoading(true);
        const query: any = {
            type: 'LUCES',
            limit: 500,
            page: 1,
        };

        getFeedback(query)
            .then((response: any) => {
                setFeedbacks(response.data);
                handleFeedback(response.data[0]);
            })
            .catch((error: any) => {
                const description = error?.response?.data?.message || error.message;
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    const handleSendFeedback = () => {
        setOpen(false);
        fetchFeedbacks();
    };

    useEffect(() => {
        fetchFeedbacks();
    }, []);

    return (
        <div className="briefcase__section briefcase__section--feedback" id="experiences">
            <ModalWrapper
                visible={open}
                onCancel={() => {
                    setOpen(false);
                    fetchFeedbacks();
                }}
            >
                <FeedbackForm handleSendFeedback={handleSendFeedback} />
            </ModalWrapper>
            <Title level={1}>
                {t('EXPERIENCES')}
            </Title>
            <div className="section__training--information-line" />
            <Space direction="vertical" size={48} className="full__width">
                {
                    loading ? (
                        <Spin />
                    ) : (
                        feedbacks.length > 0 ? (
                            <Row gutter={[24, 32]} justify="space-between" align="top">
                                <Col xs={24} xl={12}>
                                    <div className="briefcase__section--feedback-cards">
                                        {
                                            feedbacks?.map((item: any, index: number) => (
                                                <FeedbackCard feedback={item} onClick={handleFeedback} />
                                            ))
                                        }
                                    </div>
                                </Col>
                                <Col xs={24} xl={12}>
                                    <Space className="full__size" direction="vertical" size={32}>
                                        {
                                            feedbackSelected && (
                                                <div className="briefcase__section--feedback-select">
                                                    <Avatar
                                                        className="briefcase__section--feedback-select-flag"
                                                        src={findImage(feedbackSelected?.country)}
                                                        size={160}
                                                    />
                                                    <Avatar
                                                        size={64}
                                                        src={feedbackSelected.image}
                                                        className="briefcase__section--feedback-select-avatar"
                                                    />
                                                    <Space
                                                        size={4}
                                                        direction="vertical"
                                                        className="full__width"
                                                        style={{ display: 'flex', alignItems: 'center' }}
                                                    >
                                                        <Title level={4} className="briefcase__section--feedback-select-name">
                                                            {`${feedbackSelected.name} ${feedbackSelected.lastName}`}
                                                        </Title>
                                                        <Text className="briefcase__section--feedback-select-comment">
                                                            {feedbackSelected.jobPosition}
                                                        </Text>
                                                    </Space>
                                                    <Text className="briefcase__section--feedback-select-comment">
                                                        {feedbackSelected.comment}
                                                    </Text>
                                                </div>
                                            )
                                        }

                                        <Button
                                            type="primary"
                                            size="large"
                                            className="briefcase__section--groups-button"
                                            onClick={() => setOpen(true)}
                                        >
                                            {t('I_WANT_TO')}
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        ) : (
                            <Space direction="vertical" size={16} className="full__width flex-column center-column-center">
                                <Title level={4} style={{ color: '#000' }}>
                                    {t('NO_FEEDBACKS')}
                                </Title>
                                <Button
                                    type="primary"
                                    size="large"
                                    className="briefcase__section--groups-button"
                                    onClick={() => setOpen(true)}
                                >
                                    {t('I_WANT_TO')}
                                </Button>
                            </Space>
                        )
                    )
                }
            </Space>
        </div>
    );
};

export default Feedback;
