import {
    PageHeader, Avatar, Space, Spin, Typography, Tooltip, Row, Col,
} from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AiFillFacebook, AiFillInstagram, AiFillLinkedin, AiFillTwitterSquare,
} from 'react-icons/ai';
import { getMentor } from '../api/cloister';
import { ITeacher } from '../interfaces';
import Loading from './Loading';
import { getCountry } from '../utils/countries';

interface SocialInputs {
    name: string;
    label: string;
    placeholder: string;
    icon: ReactNode;
    url?: string;
}

const { Text, Title } = Typography;

const Mentor = ({ mentorID }: { mentorID: string }) => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const [loading, setLoading] = useState<boolean>(false);
    const [mentor, setMentor] = useState<ITeacher>({} as ITeacher);

    const designation = mentor?.gender !== 'HIDDEN' ? (
        mentor?.gender === 'MALE' ? 'Dr.' : 'Dra.'
    ) : '';

    const country = getCountry(mentor?.country as string);

    const socialInputs: SocialInputs[] = [
        {
            name: 'LN',
            label: tf('INPUTS.SOCIAL.LINKEDIN'),
            placeholder: tf('INPUTS.SOCIAL.LINKEDIN_PLACEHOLDER'),
            icon: <AiFillLinkedin color="#1b4484" size={24} />,
            url: mentor?.contact?.socialMedia?.LN,
        },
        {
            name: 'FB',
            label: tf('INPUTS.SOCIAL.FACEBOOK'),
            placeholder: tf('INPUTS.SOCIAL.FACEBOOK_PLACEHOLDER'),
            icon: <AiFillFacebook color="#1b4484" size={24} />,
            url: mentor?.contact?.socialMedia?.FB,
        },
        {
            name: 'TW',
            label: tf('INPUTS.SOCIAL.TWITTER'),
            placeholder: tf('INPUTS.SOCIAL.TWITTER_PLACEHOLDER'),
            icon: <AiFillTwitterSquare color="#1b4484" size={24} />,
            url: mentor?.contact?.socialMedia?.TW,
        },
        {
            name: 'IG',
            label: tf('INPUTS.SOCIAL.INSTAGRAM'),
            placeholder: tf('INPUTS.SOCIAL.INSTAGRAM_PLACEHOLDER'),
            icon: <AiFillInstagram color="#1b4484" size={24} />,
            url: mentor?.contact?.socialMedia?.IG,
        },
    ];

    const fetchMentor = () => {
        setLoading(true);
        getMentor(mentorID as string)
            .then((response: any) => setMentor(response.data))
            .catch((error: any) => console.log(error))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!mentorID) return;
        fetchMentor();
    }, [mentorID]);

    return (
        loading ? (
            <Space align="center">
                <Spin size="small" />
                <Loading />
            </Space>
        ) : (
            <div>
                <PageHeader
                    title={(
                        <Space direction="vertical" size={0}>
                            <Title level={3} style={{ margin: 0 }}>
                                {`${designation || ''} ${mentor?.name} ${mentor?.lastName}`}
                            </Title>
                            <Text>
                                {mentor?.academic?.jobPosition}
                            </Text>
                        </Space>
                    )}
                    avatar={{
                        src: mentor?.image?.original,
                        size: 68,
                    }}
                />

                <Space direction="vertical" size={16}>
                    <div style={{ maxHeight: 200, overflowY: 'scroll', paddingRight: 16 }}>
                        <Text>
                            {mentor?.academic?.biography}
                        </Text>
                    </div>

                    <Row justify="space-between" align="middle" style={{ width: '100%' }}>
                        <Col>
                            <Space size={8} align="center">
                                {
                                    socialInputs.map((item: SocialInputs) => item?.url && (
                                        <Tooltip title={item?.label} key={item.name}>
                                            <Avatar
                                                size={28}
                                                shape="square"
                                                src={item?.icon}
                                                onClick={() => window.open(item?.url, '_blank')}
                                            />
                                        </Tooltip>
                                    ))
                                }
                            </Space>
                        </Col>
                    </Row>
                </Space>

            </div>
        )
    );
};

export default Mentor;
