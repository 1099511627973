import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IExperience, IExperiencesContext } from './experiences.interface';
import { getExperiences } from '../../../api/experience';

interface Props {
    children: React.ReactNode;
}

const initialContext = {
    loading: false,
    experiences: [] as IExperience[],
    total: 0,
    paginator: {
        limit: 1,
        page: 1,
    },
    errors: [],
    onPageChange: () => {},
    fetchExperiences: () => {},
};

export const ExperiencesContext = createContext<IExperiencesContext>(initialContext);

export const ExperiencesProvider = ({ children }: Props) => {
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [experiences, setExperiences] = useState<IExperience[]>(initialContext.experiences);
    const [total, setTotal] = useState<number>(initialContext.total);
    const [paginator, setPaginator] = useState(initialContext.paginator);
    const [errors, setErrors] = useState<string[]>(initialContext.errors);

    const location = useLocation();

    const formaImage = (pictures: string[]) => pictures.map((picture: string) => ({
        original: picture,
        thumbnail: picture,
    }));

    const fetchExperiences = (tag?: string) => {
        setLoading(true);
        setErrors([]);

        const params: any = { ...paginator };

        if (tag) params.tag = tag;

        const key = location?.hash.split('=')[1];
        const page = key?.split('_')[1];

        if (page) {
            params.page = page;
        }

        getExperiences(params)
            .then((response: Partial<any>) => {
                const { data, meta } = response;
                setExperiences(data);
                setTotal(meta?.itemCount || 0);
                const newExperiences = data.map((experience: any) => ({
                    ...experience,
                    pictures: formaImage(experience.pictures),
                }));
                setExperiences(newExperiences);
            })
            .catch((error: any) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setLoading(false));
    };

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({
            page,
            limit: pageSize,
        });
    };

    useEffect(() => {
        const key = location?.hash.split('=')[1];
        const page = key?.split('_')[1];
        if (page) paginator.page = Number(page);
    }, []);

    return (
        <ExperiencesContext.Provider
            value={{
                loading,
                experiences,
                total,
                paginator,
                errors,
                onPageChange,
                fetchExperiences,
            }}
        >
            {children}
        </ExperiencesContext.Provider>
    );
};
