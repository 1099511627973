import { Inspiration, RectoralBoard, TrainingGap } from '../components/Institutional';
import BriefCase from '../components/Home/BriefCase';
import LifeLongLuces from '../components/Home/LifeLongLuces';
import Purpose from '../components/Home/Purpose';
import { Portrait } from '../components/Portrait';

const Institutional = () => (
    <>
        <RectoralBoard />
        <TrainingGap />
        <Inspiration />
        {/* <Inspiration /> */}
        {/* <DirectiveTeam /> */}
    </>
);

export default Institutional;
