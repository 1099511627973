import {
    Space, Spin, Upload, Typography,
} from 'antd';
import { useState } from 'react';
import { MdDriveFolderUpload } from 'react-icons/md';
import ImageCrop from './ImageCrop';
import common from '../../api/common';

const { Text } = Typography;

const UploadImage = ({
    value, onChange, type, name, t,
}: any) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [file, setFile] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleUpload = (data: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            setFile(reader.result);
            setVisible(true);
        });
        reader.readAsDataURL(data.file.originFileObj);
    };

    const handleUploadFile = async (file: any) => {
        setLoading(true);
        setVisible(false);
        setFile(null);
        const response = await common.uploadImageToS3(file, type, name);
        onChange(response.data);
        setLoading(false);
    };

    return (
        <div className="upload-image">
            <ImageCrop
                visible={visible}
                file={file}
                handleUploadFile={handleUploadFile}
                aspect={1}
                close={() => setVisible(false)}
            />
            <Upload
                showUploadList={false}
                onChange={handleUpload}
            >
                {
                    value ? (
                        <img src={value} alt="article" />
                    ) : (
                        loading
                            ? (<Spin />)
                            : (
                                <Space direction="vertical" size={4} className="ant-upload-items">
                                    <MdDriveFolderUpload size={45} color="#1b4484" />
                                    <Text>
                                        {t('INPUTS.IMAGE_INSTRUCTION')}
                                    </Text>
                                </Space>
                            )
                    )
                }
            </Upload>
        </div>
    );
};

export default UploadImage;
