import axios from 'axios';
import { DASHBOARD_API } from './service';
import { IFeedbackDto } from '../interfaces';

export const createFeedback = (body: IFeedbackDto) => {
    const url = `${DASHBOARD_API}/feedbacks`;
    const response = axios.post(url, body);
    return response;
};

export const getFeedback = (query: any) => {
    const url = `${DASHBOARD_API}/feedbacks`;
    const response = axios.get(url, { params: query });
    return response;
};
