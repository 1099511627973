import { Space, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import {
    Sense,
    Transversalities,
    Collective,
    Profile,
    Competencies,
    Parameters,
} from '../components/Foundations';

const { Title, Text } = Typography;

const PedagogicalFoundations = () => {
    const { t } = useTranslation('home', { keyPrefix: 'FOUNDATIONS' });

    return (
        <>
            <div className="briefcase__portrait" id="pedagogical-model">
                <div className="briefcase__portrait--position mask" />

                <Space direction="vertical" size={0} className="briefcase__portrait--title">
                    <Title level={1} style={{ color: '#FFF' }}>
                        {t('PEDAGOGICAL_MODEL')}
                    </Title>
                    <Text style={{ fontSize: 22, color: '#FFF' }}>
                        <Trans
                            t={t}
                            i18nKey="PEDAGOGICAL_MODEL_DESCRIPTION"
                            components={{
                                b: <b />,
                            }}
                        />
                    </Text>
                </Space>
            </div>
            <Collective />
            <Profile />
            <Parameters />
            <Competencies />
            <Sense />
            <Transversalities />
        </>
    );
};

export default PedagogicalFoundations;
