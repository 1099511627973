import Course from '../components/Course';
import { CourseProvider } from '../components/Course/context/courseContext';
import { ExperiencesProvider } from '../components/Experiences/context/experiencesContext';

const MainCourse = () => (
    <CourseProvider>
        <ExperiencesProvider>
            <Course />
        </ExperiencesProvider>
    </CourseProvider>
);

export default MainCourse;
