import cs from 'classnames';
import { Modal, Button, Typography } from 'antd';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdCloseCircle } from 'react-icons/io';
import { BsLightbulbFill } from 'react-icons/bs';
import Cropper from 'react-easy-crop';
import { ASPECT_RATIOS } from './constants/ratios';
import { getCroppedImage } from './utils/image.util';

interface Props {
    visible: boolean
    file: any;
    handleUploadFile: (data: any) => void;
    aspect: number;
    close: () => void;
}

const { Text, Title } = Typography;

const ImageCrop = ({
    visible, file, handleUploadFile, aspect, close,
}: Props) => {
    const { t } = useTranslation('forms', { keyPrefix: 'FORMS' });
    const [aspectSelected, setAspectSelected] = useState<any>(aspect || ASPECT_RATIOS[4]);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);

    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [loading, setLoading] = useState(false);

    const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const saveCroppedImage = useCallback(async () => {
        setLoading(true);
        try {
            const croppedImage = await getCroppedImage(
                file,
                croppedAreaPixels,
                rotation,
            );
            handleUploadFile(croppedImage);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }, [croppedAreaPixels, rotation]);

    const closableIcon = () => (
        <IoMdCloseCircle
            size={28}
            color="#1b4484"
            style={{ marginTop: 12 }}
            onClick={() => close()}
        />
    );

    return (
        <Modal
            open={visible}
            footer={null}
            closeIcon={closableIcon()}
            className="image-crop"
        >

            <div className="image-crop__body">
                <Title level={4}>
                    {t('INPUTS.IMAGE_SETTINGS')}
                </Title>

                <div className="instructions">
                    <BsLightbulbFill size={14} className="instructions-icon" />
                    <Text>
                        {t('INPUTS.IMAGE_SETTINGS_INSTRUCTIONS')}
                    </Text>
                </div>

                <div className="cropper">
                    <Cropper
                        image={file}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspectSelected.value}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </div>

                {!aspect && (
                    <div className="aspects">
                        {ASPECT_RATIOS.map((aspectRatio: any) => (
                            <div
                                key={aspectRatio.name}
                                onClick={() => setAspectSelected(aspectRatio)}
                                className={cs('aspects__ratio', { selected: aspectSelected.value === aspectRatio.value })}
                            >
                                <div className={cs('aspects__ratio--box', `aspects__${aspectRatio.name.replace(':', '-')}`)} />
                                <Text className="aspects__ratio--name">
                                    {aspectRatio.name}
                                </Text>
                            </div>
                        ))}
                    </div>
                )}

                <Button
                    block
                    type="primary"
                    loading={loading}
                    onClick={saveCroppedImage}
                    style={{ marginTop: 20 }}
                    className="image-crop__save"
                >
                    {t('ACTIONS.SAVE')}
                </Button>
            </div>
        </Modal>
    );
};

export default ImageCrop;
