import { createContext, useMemo, useState } from 'react';
import { initialContext } from './calendar.initial';
import { ICalendarContext, IEventMonth } from './calendar.interface';
import { getCalendarEvents } from '../../../api/calendar';

export const CalendarContext = createContext<ICalendarContext>(initialContext);

export const CalendarProvider = ({ children }: { children: any }) => {
    const [calendar, setCalendar] = useState<IEventMonth[] | null>(initialContext.calendar);
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [error, setError] = useState<string | null>(initialContext.error);

    const fetchCalendar = async () => {
        setLoading(true);

        getCalendarEvents()
            .then(({ data }: any) => setCalendar(data))
            .catch((error: any) => setError(error.message))
            .finally(() => setLoading(false));
    };

    const calendarProviderValue = useMemo(() => ({
        calendar,
        loading,
        error,
        fetchCalendar,
    }), [calendar, loading, error]);

    return (
        <CalendarContext.Provider
            // @ts-ignore
            value={calendarProviderValue}
        >
            {children}
        </CalendarContext.Provider>
    );
};
