import {
    Col, Row, Typography, notification,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLucesPartners } from '../../../api/users';
import { ITeacher } from '../../../interfaces';
import TeacherCard from '../../Course/components/TeacherCard';

const { Title } = Typography;

const DirectiveTeam = () => {
    const { t, i18n } = useTranslation('home', { keyPrefix: 'FOUNDATIONS' });

    const [loading, setLoading] = useState<boolean>(false);
    const [directive, setDirective] = useState<ITeacher[]>([]);
    const [total, setTotal] = useState<number>(0);

    const language = i18n.resolvedLanguage;

    const fetchDirective = () => {
        setLoading(true);

        const query: any = {
            limit: 10,
            page: 1,
            role: 'RENAMED',
        };

        getLucesPartners(query)
            .then((response: any) => {
                setDirective(response.data);
                setTotal(response.meta.itemCount);
            })
            .catch((error: any) => {
                const description = error?.response?.data?.message || error?.message;

                notification.error({
                    message: 'ERROR',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchDirective();
    }, []);

    return (
        <div className="section__training">
            <Title level={2}>
                {t('DIRECTIVE_TEAM')}
            </Title>
            <div className="section__training--information-line" />

            <div className="team">
                <Row
                    className="full__width"
                    justify="start"
                    align="top"
                    gutter={[16, 24]}
                >
                    {
                        directive.map((directive: ITeacher) => (
                            <Col xs={12} sm={8} xl={6} key={directive._id}>
                                <TeacherCard
                                    teacher={directive}
                                    desc={directive?.luces?.jobPosition[language as 'es' | 'en' | 'pt']}
                                />
                            </Col>

                        ))
                    }
                </Row>
            </div>
        </div>
    );
};

export default DirectiveTeam;
