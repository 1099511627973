import {
    Routes,
    Route,
} from 'react-router-dom';

import Home from '../screens/Home';
import Briefcase from '../screens/Briefcase';
import Supporters from '../screens/Supporters';
import PedagogicalFoundations from '../screens/PedagogicalFoundations';
import MainCourse from '../screens/Course';
import Cloister from '../screens/Cloister';
import Mentor from '../screens/Mentor';
import Institutional from '../screens/Institutional';
import Contact from '../screens/Contact';

const DashboardRouter = () => (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/supporters" element={<Supporters />} />
        <Route path="/institutional" element={<Institutional />} />
        <Route path="/briefcase" element={<Briefcase />} />
        <Route path="/briefcase/:tag" element={<MainCourse />} />
        <Route path="/foundations" element={<PedagogicalFoundations />} />
        <Route path="/cloister" element={<Cloister />} />
        <Route path="/contact" element={<Contact />} />
    </Routes>
);

export default DashboardRouter;
