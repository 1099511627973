import {
    createContext, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getCourseByTag } from '../../../api/courses';
import { ICourse } from '../../../interfaces';
import { ICourseContext } from './course.interface';
import { initialContext } from './course.initial';

export const CourseContext = createContext<ICourseContext>(initialContext);

export const CourseProvider = ({ children }: { children: any }) => {
    const { i18n } = useTranslation();

    const [course, setCourse] = useState<ICourse | null>(initialContext.course);
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [error, setError] = useState<string | null>(initialContext.error);

    const fetchCourse = async (tag: string) => {
        setLoading(true);

        const language = i18n.resolvedLanguage.toUpperCase();

        getCourseByTag(`${tag}_${language}`)
            .then(({ data }) => setCourse(data[0]))
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    };

    const cursesProviderValue = useMemo(() => ({
        course,
        loading,
        error,
        fetchCourse,
    }), [course, loading, error]);

    return (
        <CourseContext.Provider
            // @ts-ignore
            value={cursesProviderValue}
        >
            {children}
        </CourseContext.Provider>
    );
};
