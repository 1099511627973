import { ReactNode } from 'react';
import {
    Row, Col, Avatar, Typography,
} from 'antd';
import { BiDirections } from 'react-icons/bi';
import { MdTrackChanges } from 'react-icons/md';
import { RiFocus2Line } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

interface ICard {
    title: string;
    image: ReactNode;
}

const Sense = () => {
    const { t } = useTranslation('home', { keyPrefix: 'SENSE' });

    const CARDS: ICard[] = [
        {
            title: t('NEW_SENSE'),
            image: <RiFocus2Line />,
        },
        {
            title: t('CHANGE_DIRECTION'),
            image: <BiDirections />,
        },
        {
            title: t('FULL_TURNAROUND'),
            image: <MdTrackChanges />,
        },
    ];

    return (
        <div className="section__training" id="change-management">
            <Title level={2}>
                {t('SENSE_180')}
            </Title>
            <div className="section__training--information-line" />

            <Row gutter={[32, 24]}>
                {
                    CARDS.map((card) => (
                        <Col
                            key={card.title}
                            xs={24}
                            xxl={8}
                        >
                            <div className="sense__card">
                                <Avatar
                                    size={72}
                                    icon={card.image}
                                    className="sense__card--icon"
                                />
                                <Title level={3} className="none__margin">
                                    {card.title}
                                </Title>
                            </div>
                        </Col>
                    ))
                }
            </Row>
        </div>
    );
};

export default Sense;
