import { Feedback } from '../components/Briefcase';
import Calendar from '../components/Calendar';
import { CalendarProvider } from '../components/Calendar/context/calendarContext';
import {
    WhatToDoES, LifeLong, DirectiveTeam,
} from '../components/Home';
import BriefCase from '../components/Home/BriefCase';
import LifeLongLuces from '../components/Home/LifeLongLuces';
import { Portrait } from '../components/Portrait';

const Home = () => (
    <>
        {/* <LifeLong /> */}
        <Portrait />
        <LifeLongLuces />
        {/* <BriefCase /> */}
        {/* <TrainingGap /> */}
        <CalendarProvider>
            <Calendar />
        </CalendarProvider>
        <WhatToDoES />
        <Feedback />
        {/* <Inspiration /> */}
        {/* <DirectiveTeam /> */}
    </>
);

export default Home;
