import { Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
    studyPlan: string;
    duration: string;
    methodology: string;
}

const { Title, Text } = Typography;

const StudyPlan = ({ studyPlan, duration, methodology }: Props) => {
    const { t } = useTranslation('courses', { keyPrefix: 'DETAIL' });

    return (
        <Space direction="vertical" size={24}>
            <Title level={3} className="none__margin--bottom">
                {t('DURATION_PROGRAM')}
            </Title>
            <Text>
                <div dangerouslySetInnerHTML={{ __html: duration }} />
            </Text>

            <Title level={3} className="none__margin--bottom">
                {t('METHODOLOGY')}
            </Title>
            <Text>
                <div dangerouslySetInnerHTML={{ __html: methodology }} />
            </Text>

            <Title level={3} className="none__margin--bottom">
                {t('SYLLABUS')}
            </Title>

            <Text className="item__summary--text-value">
                <div dangerouslySetInnerHTML={{ __html: studyPlan }} />
            </Text>
        </Space>
    );
};

export default StudyPlan;
