import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CourseContext } from './context/courseContext';
import { Header, ManageTabs } from './components';

const Course = () => {
    const { t, i18n } = useTranslation('courses', { keyPrefix: 'DETAIL' });

    const { loading, course, fetchCourse } = useContext(CourseContext);

    const location = useLocation();
    const tag = location.pathname.split('/')[2];

    useEffect(() => {
        if (tag) fetchCourse(tag.toUpperCase());
    }, [tag, i18n.resolvedLanguage]);

    return (
        loading ? (
            <div>
                {t('GETTING_COURSE')}
            </div>
        ) : course && (
            <div className="course">
                <Header
                    name={course.name}
                    welcomePhrase={course.welcomePhrase}
                    logo={course.logo}
                    background={course.background}
                    editions={course.editions}
                    color={course.color}
                />
                <ManageTabs course={course} />
            </div>
        )
    );
};

export default Course;
