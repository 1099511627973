import { useEffect, useState } from 'react';
import {
    Col, Row, Image, Typography, Card, notification,
} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IMAGES from '../../../constants/images';
import ModalWrapper from '../../Modals';
import QuoteLeadershipForm from '../../Forms/QuoteLeadershipForm';

interface IBriefcaseItem {
    key: string;
    title: string;
    description: string;
    image: string;
    path?: string;
    color?: string;
}

const { Title } = Typography;

const Programs = () => {
    const { t } = useTranslation('home', { keyPrefix: 'BRIEFCASE' });

    const [quoteShow, setQuoteShow] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();

    const items: IBriefcaseItem[] = [
        {
            key: 'EAGLES',
            title: t('EAGLES'),
            description: t('EAGLES_DESCRIPTION'),
            image: IMAGES.logo.eagles,
            path: '/briefcase/eagles',
            color: '#f8f8f8',
        },
        /* {
            key: 'HE_ESS',
            title: t('HE_ESS'),
            description: t('HE_ESS_DESCRIPTION'),
            image: IMAGES.logo.he_ess,
            path: '/briefcase/he-ess',
            color: '#f8f8f8',
        }, */
        {
            key: 'OX_BOARD',
            title: t('OX_BOARD'),
            description: t('OX_BOARD_DESCRIPTION'),
            image: IMAGES.logo.ox_board,
            path: '/briefcase/oxboard',
            color: '#f8f8f8',
        },
        /* {
            key: 'PECES',
            title: t('PECES'),
            description: t('PECES_DESCRIPTION'),
            image: IMAGES.logo.peces,
            path: '/briefcase/peces',
            color: '#f8f8f8',
        },
        {
            key: 'RBANY',
            title: t('RBANY'),
            description: t('RBANY_DESCRIPTION'),
            image: IMAGES.logo.rbany,
            path: '/briefcase/rbany',
            color: '#f8f8f8',
        }, */
        {
            key: 'UNI_ODS',
            title: t('UNI_ODS'),
            description: t('UNI_ODS_DESCRIPTION'),
            image: IMAGES.logo.uni_ods,
            /* path: '/briefcase/uniods', */
            path: 'https://uni-ods.org/',
            color: '#f8f8f8',
        },
        {
            key: 'WORKSHOP',
            title: t('WORKSHOP'),
            description: t('WORKSHOP_DESCRIPTION'),
            image: IMAGES.logo.mrm,
            /* path: '/briefcase/libro', */
            path: 'https://www.magnificoreitormagnifico.com/',
            color: '#080B12',
        },
        /* {
            key: 'DELFINES',
            title: t('DELFINES'),
            description: t('DELFINES_DESCRIPTION'),
            image: IMAGES.logo.delfines,
            path: '/briefcase/delfines',
            color: '#f8f8f8',
        }, */
        {
            key: 'ON_DEMAND',
            title: t('ON_DEMAND'),
            description: t('ON_DEMAND_DESCRIPTION'),
            image: IMAGES.logo.horizontal,
            path: '/briefcase#programs?tab=on-demand',
            color: '#f8f8f8',
        },
    ];

    const handleLink = (item: IBriefcaseItem) => {
        const external: string[] = ['UNI_ODS', 'WORKSHOP'];
        const isNext: string[] = ['OX_BOARD'];

        if (external.includes(item.key)) {
            window.open(item.path as string, '_blank');
        } else if (isNext.includes(item.key)) {
            notification.info({
                message: t('IN_PROGRESS'),
            });
        } else if (item.key === 'ON_DEMAND') {
            navigate(item.path as string, { replace: true });
        } else {
            navigate(item.path as string);
        }
    };

    useEffect(() => {
        const { hash } = location;
        if (hash.includes('on-demand')) {
            setQuoteShow(true);
        }
    }, [location]);

    return (
        <div className="briefcase__items">
            <ModalWrapper
                visible={quoteShow}
                onCancel={() => {
                    navigate('/briefcase#programs');
                    setQuoteShow(false);
                }}
            >
                <QuoteLeadershipForm />
            </ModalWrapper>
            <Row
                align="middle"
                gutter={[56, 32]}
                justify="start"
                className="full__width full__height"
            >
                {
                    items.map((item) => (
                        <Col xs={24} lg={12} xxl={8} key={item.key}>
                            <Card
                                onClick={() => handleLink(item)}
                                bordered
                                hoverable
                                key={item.key}
                                className="none__padding briefcase__items--item"
                                cover={(
                                    <Image
                                        preview={false}
                                        src={item.image}
                                        alt={item.title}
                                        style={{ backgroundColor: item.color, padding: 64 }}
                                        width="100%"
                                        height={300}
                                    />
                                )}
                                bodyStyle={{
                                    padding: 0,
                                }}
                            >
                                <Title level={2} className="briefcase__items--item-title">
                                    {item.title}
                                </Title>
                            </Card>
                        </Col>
                    ))
                }
            </Row>
        </div>
    );
};

export default Programs;
