import {
    Avatar, Col, Row, Space, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { RiFocus2Line } from 'react-icons/ri';
import { FaAutoprefixer, FaHandshake, FaPuzzlePiece } from 'react-icons/fa';
import { HiEye, HiLightBulb, HiStar } from 'react-icons/hi';
import { GiBlackHandShield, GiHumanPyramid, GiWorld } from 'react-icons/gi';

interface IItems {
    key: string;
    label: string;
    description: string;
    active?: boolean;
    icon: any;
}

const { Title, Text } = Typography;

const WhatToDoES = () => {
    const { t } = useTranslation('home', { keyPrefix: 'WTD' });

    const items: IItems[] = [
        {
            key: 'INCLUSIVE-WELCOMING',
            label: t('INCLUSIVE_WELCOMING'),
            description: t('INCLUSIVE_WELCOMING_DESCRIPTION'),
            active: false,
            icon: <FaAutoprefixer />,
        },
        {
            key: 'QUALITY-EXCELLENCE',
            label: t('QUALITY_EXCELLENCE'),
            description: t('QUALITY_EXCELLENCE_DESCRIPTION'),
            active: false,
            icon: <HiStar />,
        },
        {
            key: 'PERTINENT-RELEVANT',
            label: t('PERTINENT_RELEVANT'),
            description: t('PERTINENT_RELEVANT_DESCRIPTION'),
            active: false,
            icon: <FaPuzzlePiece />,
        },
        {
            key: 'CREATIVITY-INNOVATION',
            label: t('CREATIVITY_INNOVATION'),
            description: t('CREATIVITY_INNOVATION_DESCRIPTION'),
            active: false,
            icon: <HiLightBulb />,
        },
        {
            key: 'VISION-FUTURE',
            label: t('VISION_FUTURE'),
            description: t('VISION_FUTURE_DESCRIPTION'),
            active: false,
            icon: <HiEye />,
        },
        {
            key: 'TRANSCENDENT-HUMAN',
            label: t('TRANSCENDENT_HUMAN'),
            description: t('TRANSCENDENT_HUMAN_DESCRIPTION'),
            active: false,
            icon: <GiHumanPyramid />,
        },
        {
            key: 'INVITATION-TO-COMMUNITY',
            label: t('INVITATION_TO_COMMUNITY'),
            description: t('INVITATION_TO_COMMUNITY_DESCRIPTION'),
            active: false,
            icon: <FaHandshake />,
        },
    ];

    return (
        <div className="section__wtd" id="education-is-the-light">
            <Title level={2}>
                {t('WHAT_TO_DO_ES')}
            </Title>
            <div className="section__training--information-line" />
            {/* <Title level={4} className="section__wtd--subtitle">
                {t('SUBTITLE')}
            </Title> */}
            <div className="section__wtd--items">
                <Row gutter={[24, 32]}>
                    {
                        items.map((item, index) => (
                            <Col xs={24} md={12} xxl={8} key={index}>
                                <div className="section__wtd--item flex-column center-column-start">
                                    <div className="section__wtd--item-label">
                                        <Space className="full__width">
                                            <Avatar
                                                src={item.icon}
                                                shape="square"
                                                size={64}
                                                className="section__wtd--item-label-icon flex-row center-row-center"
                                            />
                                            <Title level={4} className="section__wtd--item-label-title">
                                                {item.label}
                                            </Title>
                                        </Space>
                                    </div>
                                    <div className="section__wtd--item-description">
                                        <Text>
                                            {item.description}
                                        </Text>
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                </Row>

            </div>
        </div>
    );
};

export default WhatToDoES;
