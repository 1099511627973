import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const Competencies = () => {
    const { t } = useTranslation('home', { keyPrefix: 'FOUNDATIONS' });

    return (
        <div className="section__training" id="competency-management">
            <Title level={2}>
                {t('COMPETENCIES_DEVELOPMENT')}
            </Title>
            <div className="section__training--information-line" />

            <div className="full__width">
                <Row justify="space-between" align="middle" className="full__width" gutter={[24, 48]}>
                    <Col xs={24} lg={12} xxl={8} className="foundations__competencies--item">
                        <Title level={4}>
                            {t('COMPETENCIES_DEVELOPMENT_ITEM_1')}
                        </Title>
                        <Title level={4}>
                            {t('COMPETENCIES_DEVELOPMENT_ITEM_2')}
                        </Title>
                        <Title level={4}>
                            {t('COMPETENCIES_DEVELOPMENT_ITEM_3')}
                        </Title>
                    </Col>
                    <Col xs={24} lg={12} xxl={8} className="foundations__competencies--item">
                        <Title level={4}>
                            {t('COMPETENCIES_DEVELOPMENT_ITEM_4')}
                        </Title>
                        <Title level={4}>
                            {t('COMPETENCIES_DEVELOPMENT_ITEM_5')}
                        </Title>
                        <Title level={4}>
                            {t('COMPETENCIES_DEVELOPMENT_ITEM_6')}
                        </Title>
                    </Col>
                    <Col xs={24} xxl={8} className="foundations__competencies--item">
                        <Title level={4}>
                            {t('COMPETENCIES_DEVELOPMENT_ITEM_7')}
                        </Title>
                        <Title level={4}>
                            {t('COMPETENCIES_DEVELOPMENT_ITEM_8')}
                        </Title>
                        <Title level={4}>
                            {t('COMPETENCIES_DEVELOPMENT_ITEM_9')}
                        </Title>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Competencies;
