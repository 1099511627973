import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { IoCaretDownSharp } from 'react-icons/io5';

const { Option } = Select;

const SelectLanguage = () => {
    const { t, i18n } = useTranslation('shared', { keyPrefix: 'LANGUAGES' });

    const changeLanguage = (locale: string) => {
        i18n.changeLanguage(locale);
    };

    return (
        <div className="language">
            <Select
                value={i18n.resolvedLanguage}
                onChange={changeLanguage}
                suffixIcon={<IoCaretDownSharp />}
            >
                <Option value="es">
                    {t('SPANISH')}
                </Option>
                <Option value="en">
                    {t('ENGLISH')}
                </Option>
                <Option value="pt">
                    {t('PORTUGUESE')}
                </Option>
            </Select>
        </div>
    );
};

export default SelectLanguage;
