import {
    Avatar, List, Space, Typography,
} from 'antd';
import { IMember } from '../context/experiences.interface';
import { COUNTRIES } from '../../../utils/countries';

interface Props {
    member: IMember;
}

const { Item } = List;
const { Text, Title } = Typography;

const MemberCard = ({ member }: Props) => {
    const findImage = (country: string) => COUNTRIES.filter((item: any) => item.code === country)[0].flag;

    return (
        <div key={member?.name} className="card">
            <Item.Meta
                avatar={
                    member?.image?.original ? (
                        <Avatar
                            size={32}
                            src={member?.image.original}
                        />
                    ) : (
                        <Avatar
                            size={32}
                            className="card__avatar"
                        >
                            {member.name.charAt(0).toUpperCase()}
                        </Avatar>
                    )
                }
                title={(
                    <Text className="card__title">
                        {`${member.name} ${member.lastName}`}
                    </Text>
                )}
                description={(
                    <Space direction="horizontal" size={8}>
                        <Avatar
                            className="card__fixed--flag"
                            src={findImage(member?.country)}
                            shape="square"
                        />
                        <Text
                            className="card__description"
                            style={{ whiteSpace: 'break-spaces' }}
                        >
                            {member.jobPosition}
                        </Text>
                    </Space>
                )}
            />
        </div>
    );
};

export default MemberCard;
