const BUCKET_URL = 'https://rectoral-board.s3.eu-west-1.amazonaws.com';
const FIREBASE_BUCKET_URL = 'https://firebasestorage.googleapis.com/v0/b/standarrector/o/';

const IMAGES = {
    logo: {
        horizontal: `${BUCKET_URL}/logos/logo.svg`,
        horizontal_light: `${BUCKET_URL}/logos/logo-light.svg`,
        vertical_light: `${BUCKET_URL}/logos/vertical-logo-light.svg`,
        portrait: `${BUCKET_URL}/logos/luces_portrait.svg`,
        rectoral_logo: `${BUCKET_URL}/logos/rectoral_logo.png`,
        landscape: {
            ES: `${BUCKET_URL}/logos/luces_landscape_es.svg`,
            EN: `${BUCKET_URL}/logos/luces_landscape_en.svg`,
            PT: `${BUCKET_URL}/logos/luces_landscape_pt.svg`,
        },
        landscape_light: `${BUCKET_URL}/logos/luces_landscape_light.svg`,
        eagles: `${BUCKET_URL}/logos/eagles.svg`,
        ox_board: `${BUCKET_URL}/logos/ox-board.svg`,
        on_demand: `${BUCKET_URL}/logos/on-demand.svg`,
        uni_ods: `${BUCKET_URL}/logos/uni-ods.svg`,
        mrm: `${BUCKET_URL}/logos/mrm.svg`,
        peces: `${BUCKET_URL}/logos/peces.svg`,
        rbany: `${BUCKET_URL}/logos/rbany.svg`,
        delfines: `${BUCKET_URL}/logos/delfines.svg`,
        he_ess: `${BUCKET_URL}/logos/he-ess.svg`,
        whatsapp: `${BUCKET_URL}/logos/whatsapp.png`,
    },
    portrait: {
        main: `${BUCKET_URL}/portraits/main.svg`,
        presentation: `${BUCKET_URL}/portraits/luces.svg`,
    },
    curses: {
        card_curse: `${BUCKET_URL}/curses/card_curse.svg`,
    },
    illustrations: {
        life_long_learning: {
            ES: `${BUCKET_URL}/illustrations/lifelong-learning-luces-vertical-es.jpg`,
            EN: `${BUCKET_URL}/illustrations/lifelong-learning-luces-vertical-en.jpg`,
            PT: `${BUCKET_URL}/illustrations/lifelong-learning-luces-vertical-pt.jpg`,
        },
        books_graduate: `${BUCKET_URL}/illustrations/books-graduate.svg`,
        map: `${BUCKET_URL}/illustrations/map.svg`,
        sunflowers: `${BUCKET_URL}/illustrations/sunflowers.jpg`,
        lightHouse: `${BUCKET_URL}/illustrations/lightHouse.jpg`,
        eagles: `${BUCKET_URL}/illustrations/eagles.png`,
        ox_board: `${BUCKET_URL}/illustrations/oxboard.png`,
        on_demand: `${BUCKET_URL}/illustrations/ondemand.png`,
        whirl_wind: `${BUCKET_URL}/illustrations/whirl_wind.png`,
        tree: `${BUCKET_URL}/illustrations/tree.svg`,
        mentor: `${BUCKET_URL}/illustrations/mentor.jpg`,
        lighthouse_blue: `${BUCKET_URL}/illustrations/lighthouse-blue.svg`,
        lighthouse_yellow: `${BUCKET_URL}/illustrations/lighthouse-yellow.svg`,
        lighthouse_green: `${BUCKET_URL}/illustrations/lighthouse-green.svg`,
        lighthouse_orange: `${BUCKET_URL}/illustrations/lighthouse-orange.svg`,
        lighthouse_parameters: `${BUCKET_URL}/illustrations/lighthouse-parameters.jpeg`,
    },
    plots: {
        plot_complete: `${BUCKET_URL}/plots/plot-complete.svg`,
        plot_half: `${BUCKET_URL}/plots/plot-half.svg`,
        plot_diagonal: `${BUCKET_URL}/plots/plot-diagonal.svg`,
        lines: `${BUCKET_URL}/plots/lines.svg`,
    },
    memberships: {
        gold: `${BUCKET_URL}/logos/gold.png`,
        platinum: `${BUCKET_URL}/logos/platinum.png`,
        diamond: `${BUCKET_URL}/logos/diamond.png`,
    },
    sponsors: {
        oz: `${BUCKET_URL}/sponsors/oz.png`,
        abru: `${BUCKET_URL}/sponsors/abru.svg`,
        acafe: `${BUCKET_URL}/sponsors/acafe.svg`,
        amigos_por_educacion: `${BUCKET_URL}/sponsors/amigos-por-educacion.svg`,
        axis: `${BUCKET_URL}/sponsors/axis.png`,
        conif: `${BUCKET_URL}/sponsors/conif.jpeg`,
        dominus: `${BUCKET_URL}/sponsors/dominus.svg`,
        funindes: `${BUCKET_URL}/sponsors/funindes.svg`,
        gu: `${BUCKET_URL}/sponsors/gu.svg`,
        mentors_angels: `${BUCKET_URL}/sponsors/mentors-angels.svg`,
        wrw: `${BUCKET_URL}/sponsors/wrw.svg`,
        sapienza: `${BUCKET_URL}/sponsors/sapienza.jpeg`,
        rigu: `${BUCKET_URL}/sponsors/rigu.png`,
        reies: `${BUCKET_URL}/sponsors/reies.svg`,
        kactus: `${BUCKET_URL}/sponsors/kactus.svg`,
    },
};

export default IMAGES;
