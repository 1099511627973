import {
    Typography, Space, Row, Col,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { ITeacher } from '../../../interfaces';
import TeacherCard from './TeacherCard';

interface TeachersProps {
    teachers: ITeacher[];
}

const { Title, Text } = Typography;

const TeacherList = ({ teachers }: TeachersProps) => {
    const { t } = useTranslation('courses', { keyPrefix: 'DETAIL' });

    return (
        <Space direction="vertical" size={24} className="full__width">
            <Text>
                {t('TEACHERS_DESCRIPTION')}
            </Text>
            <Title level={3} className="none__margin--bottom">
                {t('MENTORS')}
            </Title>
            <Row
                className="full__width"
                justify="start"
                align="top"
                gutter={[16, 24]}
            >
                {
                    teachers.map((teacher: ITeacher) => (
                        <Col xs={12} sm={8} xl={6} key={teacher._id}>
                            <TeacherCard teacher={teacher} />
                        </Col>

                    ))
                }
            </Row>
        </Space>
    );
};

export default TeacherList;
