export const variables = {
    themeDarker: '#243162',
    themeDark: '#003166',
    themeDarkAlt: '#005ebd',
    themePrimary: '#1E3A90',
    themeSecondary: '#2b88d8',
    themeTertiary: '#71afe5',
    themeLight: '#c7e0f4',
    themeLighter: '#deecf9',
    themeLighterAlt: '#eff6fc',

    black: '#000000',
    neutralDark: '#201f1e',
    neutralPrimary: '#323130',
    neutralPrimaryAlt: '#3b3a39',
    neutralSecondary: '#605e5c',
    neutralSecondaryAlt: '#8a8886',
    neutralTertiary: '#a19f9d',
    neutralTertiaryAlt: '#c8c6c4',
    neutralQuaternary: '#d0d0d0',
    neutralQuaternaryAlt: '#dadada',
    neutralLight: '#eaeaea',
    neutralLighter: '#f4f4f4',
    neutralLighterAlt: '#f8f8f8',
    white: '#ffffff',

    blue: '#1976d2',
    yellow: '#ffb900',
    orange: '#d83b01',
    orangeLight: '#ea4300',
    orangeLighter: '#ff8c00',
    red: '#d13438',
    redDark: '#a4262c',
    green: '#107c10',
    teal: '#008272',
    purple: '#5c2d91',
    magenta: '#b4009e',

    gold: '#ffb900',
    platinum: '#e5e4e2',
    diamond: '#b9f2ff',

    legacy: '#0854A2',
    thinkTank: '#F29022',
    brands: '#A8A8A8',
    roadMap: '#EEBE33',
};
