import { List, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import IMAGES from '../../../constants/images';

const { Title, Text } = Typography;

const Transversalities = () => {
    const { t } = useTranslation('home', { keyPrefix: 'FOUNDATIONS' });

    const FOUNDATIONS_LIST: string[] = [
        'TRANSVERSALITIES_11IN_ITEM_1',
        'TRANSVERSALITIES_11IN_ITEM_2',
        'TRANSVERSALITIES_11IN_ITEM_3',
        'TRANSVERSALITIES_11IN_ITEM_4',
        'TRANSVERSALITIES_11IN_ITEM_5',
        'TRANSVERSALITIES_11IN_ITEM_6',
        'TRANSVERSALITIES_11IN_ITEM_7',
        'TRANSVERSALITIES_11IN_ITEM_8',
        'TRANSVERSALITIES_11IN_ITEM_9',
        'TRANSVERSALITIES_11IN_ITEM_10',
        'TRANSVERSALITIES_11IN_ITEM_11',
    ];

    return (
        <div className="section__training foundations__transversalities" id="transversalities-11in">
            <div
                className="foundations__transversalities--image"
                style={{ backgroundImage: `url(${IMAGES.illustrations.tree})` }}
            />
            <Title level={2}>
                {t('TRANSVERSALITIES_11IN')}
            </Title>
            <div className="section__training--information-line" />

            <div className="full__width">

                <List
                    split={false}
                    dataSource={FOUNDATIONS_LIST}
                    renderItem={(item) => (
                        <List.Item>
                            <Text className="foundations__container--items-item-text">
                                <Trans
                                    t={t}
                                    i18nKey={`${item}`}
                                    components={{ b: <b /> }}
                                />
                            </Text>
                        </List.Item>
                    )}
                />
            </div>
        </div>
    );
};

export default Transversalities;
