import {
    Typography, Space,
    Row,
    Col,
    Image,
} from 'antd';
import { useTranslation } from 'react-i18next';
import styles from '../styles.module.scss';
import IMAGES from '../../../constants/images';

const { Title, Text } = Typography;
const image: string = IMAGES.logo.landscape.ES;

const ContactText = () => {
    const { t } = useTranslation('contact', { keyPrefix: 'CONTACT' });

    return (

        <div className="section__training" id="contact">
            <Title level={2}>
                {t('TITLE')}
            </Title>
            <div className="section__training--information-line" />
            <div className="section__training--information">
                <Row className="full__width full__height">
                    <Col xs={24} xl={12} className="section__training--information-text">
                        <Title level={3}>
                            {t('GENERAL_INFORMATION')}
                        </Title>
                        <Row>
                            {t('HEAD')}
                        </Row>
                        <Row>
                            {t('CITY_HEAD')}
                        </Row>
                        <Row>
                            {t('ADDRESS_HEAD')}
                        </Row>
                        <Row>
                            &nbsp;
                        </Row>
                        <Row className={styles.textBold}>
                            {t('PROF_LUCIANO')}
                        </Row>
                        <Row>
                            {t('FOUNDER_RECTORAL_BOARD')}
                        </Row>
                        <Row>
                            {t('CEO_LUCES')}
                        </Row>
                        <Row>
                            &nbsp;
                        </Row>
                        <Row>
                            <a href="mailto:luciano.marcelino@rectoralboard.com">luciano.marcelino@rectoralboard.com</a>
                        </Row>
                        <Row>
                            <a href="mailto:gabinete@rectoralboard.com">gabinete@rectoralboard.com</a>
                        </Row>
                        <Row>
                            &nbsp;
                        </Row>
                        <Row>
                            Whats:
                            <a href="https://wa.me/5548991271973" rel="noreferrer" target="_blank">+55 (48) 9 9127 1973</a>
                        </Row>
                        <Row>
                            <a href="http://www.rectoralboard.com">www.rectoralboard.com</a>
                        </Row>
                        <Row>
                            <a href="http://luces.academy">luces.academy</a>
                        </Row>
                    </Col>
                    <Col xs={24} xl={12} className={styles.centerLogo}>
                        <Row>
                            <Image
                                loading="lazy"
                                src={image}
                                alt="Logo luces rectoral board"
                                preview={false}
                                className={styles.contactLogo}
                            />
                        </Row>
                        <Row>
                            <Image
                                className={styles.contactLogo}
                                preview={false}
                                loading="lazy"
                                src={IMAGES.logo.rectoral_logo}
                            />
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>

    );
};

export default ContactText;
