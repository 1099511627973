import {
    Space, Typography, notification, Row, Col, Image, Spin,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSponsors } from '../api/sponsors';

const { Title, Text } = Typography;
interface IPartner {
    externalLink: string;
    _id: string;
    type: 'IES' | 'NETWORK' | 'PRIVATE_INITIATIVE';
    isAvailable: boolean;
    image: {
        original: string;
        thumbnail: string;
    },
    keyName: string;
    name: string;
}
interface IPartners {
    IES: {
        count: number;
        documents: IPartner[];
    },
    NETWORK: {
        count: number;
        documents: IPartner[];
    },
    PRIVATE_INITIATIVE: {
        count: number;
        documents: IPartner[];
    },
}

const Supporters = () => {
    const { t } = useTranslation('home', { keyPrefix: 'SPONSORS' });

    const [partners, setPartners] = useState({} as IPartners);
    const [loading, setLoading] = useState(false);

    const getPartners = () => {
        setLoading(true);
        getSponsors().then(({ data }: any) => {
            setPartners(data);
        }).catch((error: any) => {
            const description = error.response?.data?.message || error.message;
            notification.error({ message: 'Error', description });
        }).finally(() => setLoading(false));
    };

    useEffect(() => {
        getPartners();
    }, []);

    const totalIES = partners?.IES?.count || 0;
    const totalNetwork = partners?.NETWORK?.count || 0;
    const totalIP = partners?.PRIVATE_INITIATIVE?.count || 0;

    return (
        <div className="sponsors">
            <Space direction="vertical" size={16} className="full__width">
                <Title level={2}>
                    {`${t('IES')} (${totalIES})`}
                </Title>
                <div className="section__training--information-line" id="academic-partners" />

                <Row gutter={[24, 24]} align="middle" justify="space-between">
                    {
                        partners?.IES?.documents?.map((partner: IPartner) => (
                            <Col
                                xs={24}
                                md={12}
                                xxl={8}
                                key={partner._id}
                                className="flex-row center-row-center"
                            >
                                {
                                    loading ? (
                                        <Spin />
                                    ) : (
                                        <a href={partner?.externalLink} target="_blank" rel="noreferrer">
                                            <Image
                                                src={partner?.image?.original}
                                                preview={false}
                                                width={140}
                                            />
                                        </a>
                                    )
                                }
                            </Col>
                        ))
                    }
                </Row>

            </Space>

            <Space direction="vertical" size={16} className="full__width">
                <Title level={2}>
                    {`${t('NETWORK')} (${totalNetwork})`}
                </Title>
                <div className="section__training--information-line" />

                <Row gutter={[24, 24]} align="middle" justify="space-between">
                    {
                        partners?.NETWORK?.documents?.map((partner: IPartner) => (
                            <Col
                                xs={24}
                                md={12}
                                xxl={8}
                                key={partner._id}
                                className="flex-row center-row-center"
                            >
                                {
                                    loading ? (
                                        <Spin />
                                    ) : (
                                        <a href={partner?.externalLink} target="_blank" rel="noreferrer">
                                            <Image
                                                src={partner?.image?.original}
                                                preview={false}
                                                width={140}
                                            />
                                        </a>
                                    )
                                }
                            </Col>
                        ))
                    }
                </Row>
            </Space>

            <Space direction="vertical" size={16} className="full__width" id="business-partners">
                <Title level={2}>
                    {`${t('ED_TECHS')} (${totalIP})`}
                </Title>
                <div className="section__training--information-line" />

                <Row gutter={[24, 24]} align="middle" justify="space-between">
                    {
                        partners?.PRIVATE_INITIATIVE?.documents?.map((partner: IPartner) => (
                            <Col
                                xs={24}
                                md={12}
                                xxl={8}
                                key={partner._id}
                                className="flex-row center-row-center"
                            >
                                {
                                    loading ? (
                                        <Spin />
                                    ) : (
                                        <a href={partner?.externalLink} target="_blank" rel="noreferrer">
                                            <Image
                                                src={partner?.image?.original}
                                                preview={false}
                                                width={140}
                                            />
                                        </a>
                                    )
                                }
                            </Col>
                        ))
                    }
                </Row>
            </Space>

        </div>
    );
};

export default Supporters;
