import {
    Typography, Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Feedback, Programs } from '../components/Briefcase';

const { Title, Text } = Typography;

const Briefcase = () => {
    const { t } = useTranslation('home', { keyPrefix: 'BRIEFCASE' });

    return (
        <div className="briefcase">

            <div className="briefcase__portrait">
                <div className="briefcase__portrait--position mask" />

                <Space direction="vertical" size={0} className="briefcase__portrait--title">
                    <Title level={1} style={{ color: '#FFF' }}>
                        {t('BRIEFCASE_INTEGRATED_AND_INTEGRATIVE')}
                    </Title>
                    <Text style={{ fontSize: 22, color: '#FFF' }}>
                        {t('BRIEFCASE_INTEGRATED_AND_INTEGRATIVE_DESCRIPTION')}
                    </Text>
                </Space>
            </div>

            <Title level={2}>
                {t('FORMATIVE_PROGRAMS')}
            </Title>
            <div className="section__training--information-line" />

            <Programs />

            {/* <Feedback /> */}
        </div>
    );
};

export default Briefcase;
