import { Image, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import IMAGES from '../../../constants/images';

const { Title, Text } = Typography;

const Profile = () => {
    const { t } = useTranslation('home', { keyPrefix: 'FOUNDATIONS' });

    return (
        <div className="foundations__section--profile">

            <Title level={1} style={{ color: '#FFF' }} className="none__margin">
                {t('PROFILE_TEACHERS')}
            </Title>

            <Text className="foundations__section--collective-text">
                <Trans
                    t={t}
                    i18nKey="PROFILE_TEACHERS_ITEM_1"
                    components={{
                        b: <b />,
                    }}
                />
            </Text>

            <Image
                loading="lazy"
                preview={false}
                width={250}
                src={IMAGES.logo.horizontal_light}
            />
        </div>
    );
};

export default Profile;
