import {
    Row, Col, Image, Typography,
} from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import IMAGES from '../../../constants/images';

const { Title, Text } = Typography;

const TrainingGap = () => {
    const { t } = useTranslation('institutional', { keyPrefix: 'TRAINING' });

    const items: String[] = [
        'PARAGRAPH_1',
        'PARAGRAPH_2',
        'PARAGRAPH_3',
    ];

    return (
        <div className="section__training" id="purpose">
            <Title level={2}>
                {t('PURPOSE')}
            </Title>
            <div className="section__training--information-line" />
            <div className="section__training--information">
                <Row className="full__width full__height">
                    <Col
                        xs={24}
                        xl={12}
                        className="section__training--information-container"
                    >
                        <Image
                            className="image-left"
                            preview={false}
                            src={IMAGES.illustrations.sunflowers}
                            height="100%"
                            width="100%"
                        />
                    </Col>
                    <Col xs={24} xl={12} className="section__training--information-text">
                        {
                            items.map((item, index) => (
                                <Text key={index} className="section__training--information-item">
                                    <Trans
                                        t={t}
                                        i18nKey={`${item}`}
                                        components={{ b: <b style={{ color: '#1E3A90' }} /> }}
                                    />
                                </Text>
                            ))
                        }
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default TrainingGap;
